.drag-container {
  height: 100%;
  display: flex;
  flex-direction: column;
  overflow: auto;
}

/* Left pane (Problem Description) */
.left-pane {
  background: #252526; /* Dark background */
  overflow: auto;
  display: flex;
  height: 100%;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  padding-right: 20px;
  border-right: 2px solid #3c3c3c; /* Soft border to separate panels */
  font-family: "Fira Code", monospace;
}

/* Problem Title */
.left-pane h2 {
  font-size: 20px;
  color: #ffffff;
  margin-bottom: 10px;
}

/* Button Styling */
.new-button {
  background: #ffaa00; /* LeetCode yellow */
  color: black;
  font-size: 14px;
  font-weight: bold;
  border: none;
  padding: 8px 15px;
  border-radius: 4px;
  cursor: pointer;
  transition: all 0.2s ease-in-out;
}

.new-button:hover {
  background: #ffcc33;
}

/* Main split container */
.split {
  display: flex;
  flex-direction: row;
  height: 100%;
  overflow: hidden;

}

/* Right pane */
.right-pane {
  display: flex;
  flex-direction: column;
  height: fit-content;
  overflow: auto;

}

/* Horizontal split for right-pane (Splits into left-section and right-section) */
.horizontal-split {
  display: flex;
  flex-direction: row;
  height: 100%;
}


/* Left section (Solution Blocks and Blocks) */
.left-section {
  display: flex;
  flex-direction: column;
  width: 100%;
  overflow: auto;
  background: #1e1e1e;
  border-right: 2px solid #3c3c3c;
}

/* Right section (Code Editor and Output Console) */
.right-section {
  display: flex;
  flex-direction: column;
  width: 100%;
  overflow: auto;
  background: #1e1e1e;
}

/* Blocks split should span full height */
.blocks-split {
  height: 100%;
  width: 100%;
  overflow: hidden;
}

/* Blocks split should span full height */
.code-output-split {
  height: 100%;
  width: 100%;
  overflow: hidden;
}

/* Code section (LeetCode-like Editor) */
.code-section {
  padding: 10px;
  background: #1e1e1e;
  border: 1px solid #3c3c3c;
  display: flex;
  flex-direction: column;
  font-family: "Fira Code", monospace;
  font-size: 14px;
  color: #d4d4d4;
}

/* Block Library */
.blocks-section {
  background: linear-gradient(to bottom right, #f4efef, #bcb7b0);
  font-size: 14px;
  color: #1e1e1e;
  font-family: var(--font-family);
  overflow: auto;
  height: auto;
  box-shadow:
    inset 0 0 6px rgba(0, 0, 0, 0.05),
    0 4px 10px rgba(0, 0, 0, 0.05);
}






.block-library {
  display: flex;
  flex-wrap: wrap;
  gap: 12px;
  padding: 10px;
  justify-content: flex-start;
}

/* Base button style for all blocks */
/* Base button style for all blocks */
.block-button {
  font-family: var(--font-family);
  padding: 14px 20px;
  font-size: 13px;
  font-weight: 600;
  border-radius: 14px;
  border: none;
  cursor: pointer;
  transition: all 0.25s ease-in-out;
  box-shadow: inset 0 -4px 0 rgba(0, 0, 0, 0.2), 0 6px 10px rgba(0, 0, 0, 0.15);
  min-width: 120px;
  max-width: 250px;
  text-align: center;
  /* white-space: nowrap; */
  color: #f0f0f0;
  position: relative;
  user-select: none;
}

/* Glow effect on hover */
.block-button:hover {
  transform: translateY(-3px);
  box-shadow: 0 0 8px rgba(255, 255, 255, 0.3),
              0 0 15px rgba(255, 255, 255, 0.2),
              inset 0 -2px 0 rgba(0, 0, 0, 0.15);
}


/* Category-specific colors — kept but enhanced slightly */
.block-button.variable {
  background: linear-gradient(145deg, #4aa3df, #2c82c9);
  color: white;
}

.block-button.loop {
  background: linear-gradient(145deg, #f6d365, #f1c40f);
  color: white;
}

.block-button.pattern {
  background: linear-gradient(145deg, #2ecc71, #239a56);
  color: white;
}

.block-button.action {
  background: linear-gradient(145deg, #e74c3c, #c0392b);
  color: white;
}

.block-button.return {
  background: linear-gradient(145deg, #34495e, #2c3e50);
  color: white;
}

.block-button.function {
  background: linear-gradient(145deg, #a66bbe, #8e44ad);
  color: white;
}

.block-button.condition {
  background: linear-gradient(145deg, #f39c12, #e67e22);
  color: white;
}

.block-button.insert {
  background: linear-gradient(145deg, #95a5a6, #7f8c8d);
  color: white;
}

.block-button.default {
  background: linear-gradient(145deg, #d5d8dc, #bdc3c7);
  color: black;
}




/* Block type-specific styles */
.variable {
  background-color: #3498db; /* Blue for variable blocks */
}

.loop {
  background-color: #ffeb3b; /* Yellow for loop blocks */
  color: black; /* Override text color to black */
  height: 80px;
}

.pattern {
  background-color: #8bc34a; /* Green for pattern blocks */
  width: fit-content;
}

.action {
  background-color: red; /* Red for action blocks */
}

.function {
  background-color: purple; /* Red for action blocks */
}

.return {
  background-color: black; /* Black for return blocks */
}

.condition {
  background-color: orange; /* Black for return blocks */
}

.insert {
  background-color: gray; /* Black for return blocks */
  height: 10px;
  width: 100px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.default {
  background-color: #f0f0f0; /* Default gray */
  color: black;
}

/* Custom scrollbar
.block-library-container::-webkit-scrollbar {
  width: 8px;
}

.block-library-container::-webkit-scrollbar-thumb {
  background-color: #bbb;
  border-radius: 10px;
} */



.workspace-section {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  width: 50%; /* Set width for side-by-side layout */
  background-color: #f9f9f9;
  border: 3px solid #ddd;
  border-radius: 15px;
  padding: 20px;
  box-shadow: 0 4px 15px rgba(0, 0, 0, 0.1);
  max-height: 500px; /* Increase height */
  overflow-y: auto;
  position: relative;
}

.solution-blocks {
  flex-grow: 1;
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 0px;
  overflow-y: auto;
  height: 80%;
  margin-top: 15px;
  /* border: dotted lightblue 1px; */
}

.non-container-block {
  font-family: var(--font-family);
  padding: 14px 20px;
  font-size: 13px;
  font-weight: 600;
  border-radius: 14px;
  border: none;
  cursor: pointer;
  transition: all 0.25s ease-in-out;
  box-shadow: inset 0 -4px 0 rgba(0, 0, 0, 0.2), 0 6px 10px rgba(0, 0, 0, 0.15);
  min-width: 120px;
  max-width: 250px;
  text-align: center;
  white-space: nowrap;
  color: #f0f0f0;
  position: relative;
  user-select: none;

  /* THIS IS WHERE YOU STYLE FOR NON-CONTAINER BLOCKS IN SOLUTION SECTION */
}

.non-container-block:hover {
  transform: translateY(-3px);
  box-shadow: 0 0 8px rgba(255, 255, 255, 0.3),
              0 0 15px rgba(255, 255, 255, 0.2),
              inset 0 -2px 0 rgba(0, 0, 0, 0.15);
}


.success {
  display: flex;
  flex-direction: column;
  justify-content: center;
  /* padding: 10px 0; */
  /* background-color: #f9f9f9; */
  box-shadow: 0 -2px 10px rgba(0, 0, 0, 0.1);
  text-align: center;
  width: 100%;
}

.success button {
  background-color: #4caf50;
  color: white;
  border: none;
  padding: 10px 20px;
  border-radius: 5px;
  cursor: pointer;
  transition: background-color 0.3s ease;
  width: 20%;
  margin: auto;
}

.success button:hover {
  background-color: #45a049;
}




.workspace-section .block {
  width: 90%;
  padding: 10px;
  margin-bottom: 10px;
  border-radius: 10px;
  display: flex;
  justify-content: center;
  align-items: center;
  box-shadow: 0 4px 10px rgba(0, 0, 0, 0.1); /* Subtle shadow */
  cursor: pointer;
  transition: transform 0.2s ease;
}

.workspace-section .block:hover {
  transform: scale(1.03);
  box-shadow: 0 4px 15px rgba(0, 0, 0, 0.2); /* Shadow on hover */
}


.block-library > div, 
.workspace-section > div > div {
    background-color: purple; /* Light yellow background */
    border-radius: 10px;
    width: 150px;
    cursor: grab;
    transition: transform 0.2s, box-shadow 0.2s; /* Smooth transition */
}

.block-library > div:hover, 
.workspace-section > div > div:hover {
    transform: scale(1.05); /* Slightly scale up on hover */
    box-shadow: 0 4px 15px rgba(0, 0, 0, 0.3); /* Add shadow on hover */
}


.highlight {
  position: relative;
  z-index: 1000;
  border: 3px dashed #3498db;
  animation: pulse 2s infinite;
}

.block-library.highlight {
  position: relative;
  z-index: 1000; /* Ensure it is above other elements */

}

.workspace-section.highlight {
  border: 3px dashed #3498db;
  padding: 10px;
}




/* .code-snippet pre {
  margin: 0; 
  padding: 0; 
} */

/* .code-snippet span {
  display: block; 
} */

.completion-message {
  background-color: #4caf50; /* Bright green background */
  color: white; /* White text for contrast */
  padding: 20px; /* Add padding for space inside the message */
  border-radius: 15px; /* Rounded corners for a smoother appearance */
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2); /* Add shadow for depth */
  text-align: center; /* Center the text */
  font-size: 20px; /* Increase font size */
  font-weight: bold; /* Make text bold */
  margin: 20px 0; /* Add margin above and below */
  border: 3px solid #2e7d32; /* Add a solid border for emphasis */
}

.completion-modal {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(242, 236, 236, 0.432);
  backdrop-filter: blur(10px);
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 1000;
}

.modal-content {
  background: white;
  padding: 30px;
  border-radius: 10px;
  text-align: center;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
}

.modal-buttons {
  margin-top: 20px;
}

.try-again-button,
.try-another-level-button {
  margin: 5px;
  padding: 10px 20px;
  font-size: 16px;
  border: none;
  border-radius: 5px;
  cursor: pointer;
}

.try-again-button {
  background-color: #4caf50;
  color: white;
}

.try-another-level-button {
  background-color: #2196f3;
  color: white;
}

.try-again-button:hover {
  background-color: #45a049;
}

.try-another-level-button:hover {
  background-color: #1976d2;
}


/* Style for individual blocks */
.block-library > div, /* Targets blocks in the library */
.workspace-section > div > div { /* Targets blocks in the workspace */
  background-color: #f4c21f; /* Light gray background */
  border-radius: 5px; /* Rounded corners */
  width: 150px;
  cursor: grab; /* Indicate draggability */
}

/* Style for blocks being dragged */
.block-library > div[style*="opacity: 0.5"], /* Targets dragging blocks in the library */
.workspace-section > div > div[style*="opacity: 0.5"] { /* Targets dragging blocks in the workspace */
  opacity: 0.5; /* Make it semi-transparent */
  box-shadow: 2px 2px 5px rgba(0, 0, 0, 0.2); /* Add a subtle shadow */
}

/* Style for slots */
.slot {
  height: 50px;
  border: 1px dashed gray;
  margin: 10px 0;
  background-color: lightyellow;
  display: flex;
  align-items: center;
  justify-content: center;
}

.block {
  padding: 10px;
  margin: 5px;
  border: 1px solid gray;
  background-color: white;
  border-radius: 15px;
  position: relative;
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.2);
  width: 200px;
  height: 50px;
  display: flex;
  justify-content: center;
  align-items: center;
}

/* Add a tab at the top of the block */
.block:before {
  content: '';
  position: absolute;
  top: -10px;
  left: 50%;
  transform: translateX(-50%);
  width: 20px;
  height: 20px;
  background-color: white;
  border-radius: 50%;
  border: 1px solid gray;
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.2);
}

/* Add a slot at the bottom of the block */
.block:after {
  content: '';
  position: absolute;
  bottom: -10px;
  left: 50%;
  transform: translateX(-50%);
  width: 20px;
  height: 20px;
  background-color: white;
  border-radius: 50%;
  border: 1px solid gray;
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.2);
}

/* Style for different block types */
.for-loop {
  background-color: #ffeb3b; /* Yellow for loops */
  width: 250px;
}

.condition {
  background-color: #8bc34a; /* Green for conditions */
}

.variable {
  background-color: #2196F3; /* Blue for variables */
}

.block-slot {
  margin-top: 10px;
  border: 1px dashed gray;
  padding: 10px;
}

.button {
  background-color: #4caf50; /* Green */
  color: white;
  border: none;
  border-radius: 10px;
  padding: 10px 20px;
  cursor: pointer;
  transition: background-color 0.3s; /* Smooth transition */
}

.button:hover {
  background-color: #45a049; /* Darker green on hover */
}

/* Add highlight style */
.highlight {
  /* background-color: pink;  */
  animation: pulse 2s infinite; /* Optional pulse animation for a more dynamic effect */
}

/* Custom Hint Popup */
.hint-popup {
  background: linear-gradient(to right, #f9f9f9, #fff);
  border-radius: 10px;
  padding: 20px;
  font-family: var(--font-family);
}

.hint-popup ul {
  color: #555;
  font-size: 16px;
}

.hint-popup ul li {
  margin-bottom: 10px;
  animation: fadeIn 0.5s ease-in-out;
}

.placeholder-block {
  background-color: #f9f9f9; /* Light background */
  border: 2px dashed gray; /* Dashed border for placeholder */
  color: #999; /* Gray text color */
  padding: 10px;
  border-radius: 5px;
  text-align: center;
  font-style: italic; /* Optional: Make text italic */
  transition: background-color 0.3s ease;
}

.placeholder-block:hover {
  background-color: #e0e0e0; /* Slightly darker on hover */
  cursor: pointer; /* Change cursor on hover */
}

/* Ensure full viewport usage */
.new-container {
  height: 100vh; /* Full screen height */
  display: flex;
  flex-direction: column;
  overflow: hidden; /* Prevent unwanted scrollbars */
  background-color: #1e1e1e; /* Dark background */
  color: #d4d4d4; /* LeetCode-style text color */
}

/* Main split container */
.split {
  display: flex;
  flex-direction: row;
  height: 100vh;
  overflow: hidden;
}

/* Left pane (Problem Description) */
.left-pane {
  background: #252526; /* Dark background */
  overflow-x: hidden;
  overflow-y: auto;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  padding-right: 20px;
  border-right: 2px solid #3c3c3c; /* Soft border to separate panels */
  font-family: "Fira Code", monospace;
}

/* Problem Title */
.left-pane h2 {
  font-size: 20px;
  color: #ffffff;
  margin-bottom: 10px;
}

/* Problem Description */
.new-problem-description {
  font-size: 14px;
  line-height: 1.5;
  color: #d4d4d4;
}

/* Code example box */
.new-example-card {
  background: #2d2d2d;
  padding: 10px;
  border-radius: 5px;
  margin-bottom: 10px;
  font-size: 14px;
  font-family: "Fira Code", monospace;
  border-left: 4px solid #ffaa00; /* Highlight color */
}

/* Right pane */
.right-pane {
  display: flex;
  flex-direction: column;
  height: 100%;
  overflow: hidden;
}

/* Horizontal split for right-pane (Splits into left-section and right-section) */
.horizontal-split {
  display: flex;
  flex-direction: row;
  height: 100%;
}

/* Left section (Solution Blocks and Blocks) */
.left-section {
  display: flex;
  flex-direction: column;
  width: 100%;
  overflow: hidden;
  background: #1e1e1e;
  border-right: 2px solid #3c3c3c;
  flex-grow: 1;
}

/* Right section (Code Editor and Output Console) */
.right-section {
  display: flex;
  flex-direction: column;
  width: 100%;
  overflow: hidden;
  background: #1e1e1e;
}

/* Blocks split should span full height */
.blocks-split {
  height: 100%;
  width: 100%;
  overflow: hidden;
}

/* Solution Blocks & Block Library */
.solution-section {
  /* padding: 20px; */
  background: #ffffff;
  font-size: 14px;
  color: #1e1e1e; /* marker-like text color */
  font-family: 'Fira Code', monospace;
  overflow: auto;
  height: auto;
  flex-grow: 1;
  border-radius: 12px;
  box-shadow:
    inset 0 0 6px rgba(0, 0, 0, 0.05),
    0 4px 10px rgba(0, 0, 0, 0.05); /* slight board pop */
  border: 2px solid #e0e0e0; /* faint gray frame */
}


/* Code & Output vertical split */
.code-output-split {
  height: 100%;
  width: 100%;
  overflow: hidden;
}

/* Code section (LeetCode-like Editor) */
.code-section {
  display: flex;;
  padding: 0px;
  background: #1e1e1e;
  border: 1px solid #3c3c3c;
  display: flex;
  flex-direction: column;
  font-family: "Fira Code", monospace;
  font-size: 14px;
  color: #d4d4d4;
  overflow: auto;
}

/* Output section (LeetCode console style) */
.output-section {
  /* padding: 10px; */
  background: #252526;
  border: 1px solid #3c3c3c;
  display: flex;
  flex-direction: column;
  color: #d4d4d4;
  font-size: 14px;
  font-family: "Fira Code", monospace;
  overflow: auto;
}

/* Buttons - Similar to LeetCode */
.new-button {
  background: #ffaa00; /* LeetCode yellow */
  color: black;
  font-size: 14px;
  font-weight: bold;
  border: none;
  padding: 8px 15px;
  border-radius: 4px;
  cursor: pointer;
  transition: all 0.2s ease-in-out;
}

.new-button:hover {
  background: #ffcc33;
}

/* Style for the vertical gutter (between columns) */


/* Add dots to the gutter */
.split .gutter::before,
.split .gutter::after {
  content: "•";
  font-size: 14px;
  color: gray;
  position: absolute;
  left: 50%;
  transform: translateX(-50%);
  white-space: nowrap;
  pointer-events: none;
}

/* Place dots slightly higher and lower for spacing */
.split .gutter::before {
  top: 30%;
}

.split .gutter::after {
  top: 60%;
}

/* Style for the horizontal gutter (between rows) */
.horizontal-split .gutter {
  background-color: #3c3c3c;
  cursor: ew-resize; /* Up-down resize cursor */
}

/* Dots for the horizontal gutters */
.horizontal-split .gutter::before,
.horizontal-split .gutter::after {
  content: "•";
  font-size: 14px;
  color: gray;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  pointer-events: none;
}


/* Styling for the draggable gutters */
.gutter {
  background-color: #3c3c3c;
  background-repeat: no-repeat;
  background-position: center;
  cursor: col-resize;
  transition: background-color 0.2s ease-in-out, box-shadow 0.2s ease-in-out;
  position: relative;
}

/* Gutter for vertical splits */
.gutter.gutter-vertical {
  cursor: row-resize;
}

/* Highlight effect on hover */
.gutter:hover {
  background-color: #e67e22; /* LeetCode yellow */
  box-shadow: 0 0 5px rgba(255, 170, 0, 0.7); /* Subtle glow effect */
}

/* Keep the highlight color while mouse is pressed */
.gutter:active {
  background-color: #e67e22; /* Slightly darker orange when clicked */
  box-shadow: 0 0 8px rgba(255, 136, 0, 0.9); /* More intense glow */
}

.shake {
  animation: shake 0.3s;
}

@keyframes shake {
  0% { transform: translateX(0); }
  25% { transform: translateX(-6px); }
  50% { transform: translateX(6px); }
  75% { transform: translateX(-6px); }
  100% { transform: translateX(0); }
}


.error-toast {
  position: fixed;
  bottom: 20px;
  left: 50%;
  transform: translateX(-50%);
  background-color: #ff4d4f;
  color: white;
  padding: 12px 20px;
  border-radius: 8px;
  font-weight: bold;
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.2);
  z-index: 9999;
  animation: fadeSlideIn 0.3s ease-in-out;
}

@keyframes fadeSlideIn {
  from {
    opacity: 0;
    transform: translateX(-50%) translateY(10px);
  }
  to {
    opacity: 1;
    transform: translateX(-50%) translateY(0);
  }
}


@keyframes fadeIn {
  from {
    opacity: 0;
    transform: translateY(-10px);
  }
  to {
    opacity: 1;
    transform: translateY(0);
  }
}

@keyframes pulse {
  0% {
    transform: scale(1);
  }
  50% {
    transform: scale(1.5);
  }
  100% {
    transform: scale(1);
  }
}

.language-selector {
  margin: 10px 0;
  display: flex;
  align-items: center;
}

.language-selector label {
  margin-right: 10px;
  font-weight: bold;
}

.language-selector select {
  padding: 5px;
  font-size: 16px;
}

.pro-level-popup {
  font-size: 1rem; /* Adjust text size */
  background-color: #fefae0; /* Light yellow background */
  color: #6b705c; /* Neutral text color */
}

.level-indicator {
  display: flex;
  justify-content: flex-start; /* Align the button to the left */
  align-items: center; /* Vertically align with any surrounding content */
  margin-bottom: 10px; /* Add some spacing below the button */
}


.level-button.disabled {
  cursor: default; /* Change cursor to indicate non-clickable */

  pointer-events: none; /* Disable pointer events */
  box-shadow: none; /* Remove hover shadow effect */
}

/* Solution Tabs for Intermediate Level */
.solution-tabs {
  display: flex;
  flex-wrap: wrap; /* Allows wrapping on small screens */
  justify-content: center;
  gap: 12px;
  margin-bottom: 15px;
  padding: 10px;
  background: rgba(50, 50, 50, 0.9);
  border-radius: 8px;
}

.solution-tab {
  padding: 10px 20px;
  border-radius: 20px;
  background: #ddd;
  cursor: pointer;
  transition: all 0.3s ease;
  font-size: 14px;
  font-weight: bold;
  text-align: center;
  min-width: 120px;
  box-shadow: 0 3px 5px rgba(0, 0, 0, 0.2);
}

.solution-tab:hover {
  background: #ffcc33;
  transform: translateY(-3px);
}

.solution-tab.active {
  background: #ffaa00;
  color: white;
  box-shadow: 0 5px 10px rgba(0, 0, 0, 0.3);
  transform: translateY(-5px);
}

.level-slider {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 10px;
  margin-bottom: 20px;
}

/* .slider-button {
  padding: 10px 20px;
  border: 1px solid #ccc;
  border-radius: 5px;
  background-color: #f5f5f5;
  color: #333;
  cursor: pointer;
  transition: background-color 0.3s, color 0.3s;
}

.slider-button.active {
  background-color: #007bff;
  color: white;
  border-color: #007bff;
}

.slider-button:hover {
  background-color: #e0e0e0;
  color: #000;
} */



@keyframes pixelate {
  0% {
    filter: none;
    opacity: 1;
  }
  40% {
    filter: none; /* Stay solid for 80% of the duration */
    opacity: 1;
  }
  70% {
    filter: blur(1px);
  }
  85% {
    filter: blur(3px) brightness(0.9);
  }
  100% {
    filter: blur(8px) brightness(0.4);
    opacity: 0;
  }
}

.pixelate-disappear {
  animation: pixelate 10s forwards; /* 8 seconds total, 5 seconds solid, 3 seconds pixelated */
}


/* Mobile-specific styles */
@media (max-width: 768px) {
  .mobile-contianer {
    border: 1px black solid;
  }

  .the-rest {
    flex-direction: column;
    align-items: center;
    justify-content: center;
    padding: 5px; /* Add padding for better spacing */
    width: 100%;
  }

  .workspace-section, .code-snippet {
    width: 100%; /* Make sections take up more width on mobile */
    margin-bottom: 20px; /* Add spacing between sections */
  }

  .workspace-section {
    padding: 15px; /* Increase padding for better readability */
    max-height: none; /* Remove height restriction */
    font-size: 10px; /* Increase font size for readability */
  }

  .solution-blocks {
    display: flex;
    /* flex-wrap: wrap; */
    justify-content: center; /* Center the blocks */
    align-items: flex-start;
    gap: 0px; /* Less gap between the blocks */
  }

  .code-snippet {
    padding: 15px; /* Increase padding */
    font-size: 14px; /* Adjust font size for mobile readability */
    max-height: none; /* Allow the code section to grow as needed */
  }

  /* Adjust font sizes for smaller screens */
  .code-snippet-header, .solution-header {
    font-size: 22px; /* Slightly reduce font size for headers */
  }

  /* Ensure buttons are easy to interact with on mobile */
  .success button {
    width: 50%; /* Make the button larger */
    font-size: 18px; /* Increase font size */
  }

  .block-library-scrollable {
    max-height: 250px; /* Give more space for scrolling blocks */
  }

  .solution-blocks > div {
    padding: 1px;
    font-size: 10px; /* Adjust font size for block content */
  }

  .cm-s-material.CodeMirror {
    font-size: 14px; /* Adjust CodeMirror font size */
  }

  .workspace-section .block {
    width: 100%; /* Full width blocks */
    padding: 10px;
    margin: 10px 0;
    font-size: 14px; /* Adjust font size inside blocks */
  }

  .block-button {
    width: 100px; /* Smaller width for the block buttons */
    height: 40px;
    font-size: 14px; /* Smaller font size */
    padding: 5px;
    margin: 5px;
  }

  .solution-blocks .block {
    padding: 5px;
    margin-bottom: 0px; /* Reduce space between blocks */
    border-radius: 5px; /* Slightly smaller radius */
    display: flex;
    justify-content: center;
    align-items: center;
    text-align: center;
    overflow: hidden; /* Prevent text overflow */
    text-overflow: ellipsis; /* Add ellipsis if the text overflows */
    white-space: nowrap; /* Prevent text wrapping */
    font-size: 14px; /* Default font size */
    line-height: 1.2; /* Adjust line height for better readability */
    word-break: break-word; /* Break long words if necessary */
    box-sizing: border-box; /* Include padding in width/height calculations */
  }

  /* Adjustments for the block library section */
  .block-library-container {
    max-height: 250px; /* Reduce height for mobile view */
  }

  .solution-tabs {
    display: flex;
    gap: 10px;
    margin-bottom: 20px;
  }
  
  .solution-tab {
    padding: 10px;
    border: 1px solid gray;
    cursor: pointer;
    background-color: lightgray;
  }
  
  .solution-tab.active {
    background-color: blue;
    color: white;
  }
  
  .block-button.highlight {
    border: 5px solid pink !important; /* Change the color and thickness as needed */
  }

 
  
}

  