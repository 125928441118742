.new-container {
  display: flex;
  flex-direction: column;
  background-color: #1e1e1e;
  color: #d4d4d4; 
  flex-grow: 1;
  overflow: auto; /* Prevent scrolling */
}

.language-selector-container {
  display: flex;
  justify-content: space-between;
  align-items: center;
  background-color: #252526;
  padding: 0px 16px; /* 🧼 Reduced padding */
  border-bottom: 1px solid #3c3c3c;
  box-shadow: 0 1px 2px rgba(0, 0, 0, 0.1); /* Slightly subtler shadow */
  flex-wrap: wrap;
  gap: 6px; /* Less gap */
  min-height: 40px; /* Define a clean min height */
}


.topbar-left,
.topbar-center,
.topbar-right {
  flex: 1;
  display: flex;
  align-items: center;
}

.topbar-left {
  justify-content: flex-end;
}

.topbar-center {
  justify-content: flex-start;
}

.topbar-right {
  justify-content: flex-end;
}

.language-selector label {
  color: #d4d4d4;
  font-weight: bold;
  margin-right: 8px;
}

.language-selector select {
  background-color: #1e1e1e;
  color: #d4d4d4;
  border: 1px solid #3c3c3c;
  border-radius: 6px;
  padding: 5px 10px;
  font-family: "Fira Code", monospace;
}



.workspace-container {
  display: flex;
  flex-direction: column;
  overflow: auto;
  height: 100%;
}

.level-button-prob-page {
  padding: 12px 20px;
  width: 100%;
  margin: 0 5px;
  border-radius: 8px;
  font-size: 1rem;
  font-weight: bold;
  color: #334155; /* Calm dark-gray text */
  background-color: #e0e7ff; /* Light lavender */
  cursor: pointer;
  border: none;
  transition: background-color 0.3s ease, box-shadow 0.3s ease;
}

/* Highlight the active button */
.level-button-prob-page.active {
  background-color: #c7d2fe; /* Slightly darker lavender for active */
  box-shadow: 0 4px 8px rgba(51, 65, 85, 0.2); /* Soft shadow */
  font-weight: bold;
}

/* Add a hover effect for all buttons */
.level-button-prob-page:hover {
  background-color: #dbeafe; /* Very light blue on hover */
}

.solution-banners {
  display: flex;
  flex-direction: column;
  gap: 10px;
}

.completion-banner {
  background-color: #d4edda;
  color: #155724;
  padding: 10px;
  border-radius: 5px;
  text-align: center;
  font-weight: bold;
}



.language-selector label {
  margin-right: 10px;
}

.bug-report-button {
  background-color: #1abc9c; /* Match the theme color */
  color: white;
  border: none;
  border-radius: 5px;
  padding: 8px 15px;
  cursor: pointer;
  font-family: "Fira Code", monospace;
  font-size: 14px;
  transition: background-color 0.3s ease, transform 0.2s ease;
}

.bug-report-button:hover {
  background-color: #16a085; /* Slightly darker shade */
  transform: scale(1.05); /* Slight zoom effect */
}

.bug-report-button:active {
  transform: scale(1); /* Remove zoom on click */
}

.mode-buttons {
  display: flex;
  gap: 10px;
  margin-left: 20px;
}

.mode-buttons button {
  background-color: #2c3e50; /* Dark blue-gray */
  color: white;
  border: none;
  padding: 10px 20px;
  border-radius: 6px;
  font-size: 14px;
  font-weight: bold;
  cursor: pointer;
  transition: all 0.3s ease-in-out;
}

.mode-buttons button:hover {
  background-color: #34495e; /* Slightly lighter blue-gray */
  transform: scale(1.05);
}

.mode-buttons button:active {
  transform: scale(0.95);
}

/* 🎯 Highlight the active button */
.mode-buttons button.active {
  background-color: #3498db; /* Bright blue for active mode */
  color: white;
  box-shadow: 0 0 10px rgba(52, 152, 219, 0.8);
  transform: scale(1.1);
}