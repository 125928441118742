.code-snippet.highlight {
    background-color: rgba(52, 152, 219, 0.2);
    border: 2px solid #3498db;
  }

  .code-editor-sticky {
    display: flex;
    justify-content: space-between;
    font-size: 18px;
    background-color: rgba(72, 67, 67);
    position: sticky;
    top: 0;
    z-index: 1;
  }

  .code-button {
    background-color: #1abc9c; /* Match the theme color */
    color: white;
    border: none;
    border-radius: 5px;
    padding: 8px 15px;
    cursor: pointer;
    font-family: var(--font-family);
  }
  
  .code-snippet-header {
    margin: 1px;
    align-self: center;
    font-size: 18px;
  }
  
  .code-mirror-settings {
    height: 100%;
    width: 100%
  }

  .react-codemirror2 {
    height: 100%;
    /* overflow: auto; */
  }
  
  .cm-s-material.CodeMirror {
    background-color: #263238; /* Match editor background */
    color: #dcdcdc; /* Text color */
    /* height: auto; Allow natural height growth */
    min-height: 100%; /* Ensure enough space */
    margin-bottom: 10px;
    padding: 10px;
    width: 100%;
    font-family: var(--font-family);
    display: flex;
    flex-direction: column;
    overflow: hidden;
}

/* Ensure the scrollbar is below the content */
.CodeMirror-scroll {
    padding-bottom: 20px; /* Adds space for scrollbar */
    overflow: hidden !important; /* Ensure both scrollbars appear if needed */
}
  
  .cm-s-material .CodeMirror-gutters {
    background-color: #263238; /* Gutters same background */
    border-right: none;
  }
  
  .cm-s-material .CodeMirror-linenumber {
    color: #90a4ae; /* Line number color */
  }
  
  .check-button {
    height: 20%;
    margin: auto;
  }