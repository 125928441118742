/* BottomNavBar.css */

.bottom-navbar {
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 15px 20px;
  height: 30px;
  flex-shrink: 0;
  background-color: #333; /* Same as Navbar background */
  color: white;
  font-family: "FiraCode", monospace;
  box-shadow: 0 -4px 15px rgba(0, 0, 0, 0.2); /* Subtle shadow at the top */
  position: fixed; /* Ensures it stays fixed at the bottom */
  bottom: 0; /* Stick to the bottom */
  width: 100%; /* Stretch across the width of the screen */
  z-index: 1050; /* Increase if elements overlap */
}

/* Prevent content from being hidden behind BottomNavBar */


.bottom-menu {
  display: flex;
  align-items: center;
}

.menu-item.bottom-contact {
  margin: 0 10px; /* Match spacing from Navbar */
  font-size: 18px;
  color: white;
  text-decoration: none;
  background-color: transparent; /* Matches the Login button */
  border: 1px solid white; /* Border to match Login */
  border-radius: 5px; /* Rounded corners */
  padding: 5px 15px; /* Add padding for a button-like look */
  transition: all 0.3s ease; /* Smooth transition for hover */
}

.menu-item.bottom-contact:hover {
  color: #1abc9c; /* Same hover effect as Login button */
  background-color: white; /* Change background on hover */
  border-color: #1abc9c; /* Match hover border color */
  text-decoration: none;
}
