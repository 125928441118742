/* General Layout */
body {
  font-family: "Inter", sans-serif;
  margin: 0;
  padding: 0;
  background-color: #f9f9f9;
  color: #2d3436;
  line-height: 1.6;
}

.homepage-container {
  /* max-width: 1200px; */
  width: 100%;
  margin: auto;
  padding: 0 20px;
  margin-bottom: 50px;
}

.animation-buttons-container {
  display: flex;
  justify-content: flex-start;
}

.top-logo-bar {
  display: flex;
  justify-content: flex-start;
  padding: 10px;
}

.animation-holder-row {
  display: flex;
  justify-content: center;
  align-items: flex-start;
  gap: 70px;
  padding: 60px 20px;
  max-width: 1200px;
  margin: 0 auto;
  flex-wrap: wrap;
}

.animation-left {
  flex: 1;
  min-width: 360px;
  max-width: 600px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.animation-right {
  flex: 1;
  display: flex;
  justify-content: center;
  /* min-width: 300px;
  max-width: 500px; */
  padding: 20px;
  /* background-color: #ffffff; */
  border-radius: 12px;
  /* box-shadow: 0 8px 24px rgba(0, 0, 0, 0.1); */
  animation: popIn 0.3s ease;
  align-self: center;
}

.bubble-title {
  font-size: 1.25rem;
  font-weight: 700;
  color: #1abc9c;
  margin-bottom: 12px;
  font-family: "Inter", sans-serif;
}

.scroll-down-arrow {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: fit-content;
  margin: auto;
  margin-top: 50px;
  cursor: pointer;
  animation: fadeIn 1s ease-in-out;
}

.scroll-down-arrow .arrow {
  font-size: 1.2rem;
  color: #2d3436;
  animation: bounceArrow 1.8s infinite;
  opacity: 0.85;
}

.scroll-down-arrow .arrow:nth-child(2) {
  animation-delay: 0.2s;
}

.scroll-down-arrow .arrow:nth-child(3) {
  animation-delay: 0.4s;
}

@keyframes bounceArrow {
  0%, 100% {
    transform: translateY(0px);
  }
  50% {
    transform: translateY(6px);
  }
}



.animation-info-container {
  /* background-color: #ffffff; */
  border: 1px solid #eaeaea;
  border-radius: 12px;
  padding: 24px 28px;
  box-shadow: 0 4px 16px rgba(0, 0, 0, 0.394);
  max-width: 480px;
  width: 60%;
  min-height: 160px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  font-family: "Inter", sans-serif;
  color: #2d3436;
  animation: fadeIn 0.4s ease;
  transition: all 0.3s ease;
  justify-self: center;
}

@keyframes fadeIn {
  from {
    opacity: 0;
    transform: translateY(6px);
  }
  to {
    opacity: 1;
    transform: translateY(0);
  }
}

.animation-info-container-multi {
  display: flex;
  flex-direction: column;
  gap: 16px;
  border: 1px solid #eaeaea;
  border-radius: 12px;
  padding: 24px 28px;
  box-shadow: 0 4px 16px rgba(0, 0, 0, 0.2);
  max-width: 480px;
  width: 60%;
  background-color: #ffffff;
  transition: all 0.3s ease;
}

.info-section {
  cursor: pointer;
  padding: 14px 16px;
  border-radius: 12px;
  background-color: #ffffff;
  border: 2px solid transparent;
  transition: all 0.3s ease;
  box-shadow: 0 2px 6px rgba(0, 0, 0, 0.366);
  position: relative;
}

.info-section:hover {
  background-color: #eafaf6;
  border-color: #1abc9c;
  box-shadow: 0 4px 12px rgba(0, 0, 0, 0.08);
  transform: scale(1.02);
}

.info-section.expanded {
  background-color: #eafaf6;
  border-color: #1abc9c;
  box-shadow: 0 4px 16px rgba(0, 0, 0, 0.1);
}


.info-section:hover {
  background-color: #f5f5f5;
}

.info-title {
  font-size: 1.15rem;
  font-weight: 600;
  color: #1abc9c;
}

.info-description {
  margin-top: 8px;
  animation: fadeIn 0.3s ease;
}


/* .bubble-title {
  font-size: 1.3rem;
  font-weight: 700;
  color: #1abc9c;
  margin-bottom: 12px;
}

.animation-info-container p {
  font-size: 1.05rem;
  color: #2d3436;
  margin: 0;
}

.animation-info-container strong {
  color: #1abc9c;
  font-weight: 600;
}

.animation-info-container em {
  font-style: italic;
  color: #3498db;
}

.animation-info-container code {
  background: #ecf0f1;
  padding: 2px 6px;
  border-radius: 6px;
  font-size: 0.95rem;
  font-family: "Fira Code", monospace;
  color: #e67e22;
}

.bubble-default {
  font-size: 1.1rem;
  color: #636e72;
  text-align: center;
  font-weight: bold;
} */


@keyframes popIn {
  0% {
    opacity: 0;
    transform: translateY(10px) scale(0.97);
  }
  100% {
    opacity: 1;
    transform: translateY(0px) scale(1);
  }
}


.centered-headline-section {
  text-align: center;
  margin: 0 auto;
  margin-top: 20px;
  max-width: 700px;
}

.centered-headline-section h1 {
  font-size: 3.8rem;
  font-weight: 800;
  line-height: 1.2;
  margin-bottom: 18px;
  color: #2d3436;
}

.centered-headline-section p {
  font-size: 1.2rem;
  color: #636e72;
  margin-bottom: 30px;
}

.hero-buttons-vertical {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 18px;
}




/* HERO SECTION – Cleaned & Updated */

.hero-section {
  display: flex;
  flex-direction: column;
  width: 100vw;
  align-items: center;
  padding: 0px 20px 0;
  gap: 30px;
}

/* Headline centered, 2-line layout */
.hero-headline {
  display: flex;
  /* justify-content: center; */
  /* text-align: center; */
  padding-left: 220px;
  gap: 90px;
  width: 100%;
  margin-bottom: 0;
}

.hero-headline h1 {
  font-size: 4.5rem;
  font-weight: 800;
  line-height: 1.2;
  color: #2d3436;
  margin-bottom: 16px;
}

.hero-headline p {
  font-size: 1.2rem;
  color: #636e72;
  margin: 0 auto;
  max-width: 600px;
}

.hero-logo-brand {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  margin-top: 10px;
}

.logo-wrapper {
  display: flex;
  align-items: center;
  gap: 16px;
}

.logo-squares {
  display: grid;
  grid-template-columns: repeat(2, 10px);
  grid-template-rows: repeat(2, 10px);
  gap: 5px;
}

.square {
  width: 10px;
  height: 10px;
  border-radius: 2px;
  background-color: #1abc9c;
  transition: transform 0.2s ease;
}

.square1 {
  background-color: #edf2f1;
  border: 1px rgba(0, 0, 0, 0.592) solid;
}
.square2 {
  background-color: #000302;
  border: 1px #1abc9c solid;
}
.square3 {
  background-color: #edf2f1;
  border: 1px rgba(0, 0, 0, 0.592) solid;
}
.square4 {
  background-color: #1abc9c;
  border: 1px black solid;
}

.blockcode-logo {
  display: flex;
  align-items: center;
  font-family: "Inter", sans-serif;
  font-weight: 700;
  font-size: 2.6rem;
  letter-spacing: -0.5px;
}

.logo-block {
  color: #2d3436;
  margin-right: 0px;
}

.logo-divider {
  color: #dcdcdc;
  margin: 0 5px;
  font-weight: 400;
}

.logo-code {
  font-family: "Source Code Pro", monospace;
  color: #1abc9c;
  font-weight: 600;
}

@keyframes popIn {
  0% {
    opacity: 0;
    transform: translateY(10px) scale(0.95);
  }
  100% {
    opacity: 1;
    transform: translateY(0px) scale(1);
  }
}

/* Highlight color for keyword spans */
.highlight-home {
  color: #1abc9c;
}

/* Row layout: Animation left, Buttons right */
.hero-main-row {
  display: flex;
  /* justify-content: center; */
  align-items: flex-start;
  width: 90%;
  gap: 40px; /* increased spacing */
  /* flex-wrap: wrap; */
  margin-top: -10px;
}

/* Left animation */
.hero-animation {
  /* max-width: 500px; */
  transform: scale(0.95);
  transform-origin: top left;
}

/* Right buttons – stacked */
.hero-buttons-vertical {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  gap: 20px;
  margin-top: 30px;
}

.hero-buttons-vertical .cta-button {
  width: 230px;
  padding: 14px 26px;
  border-radius: 999px;
  font-size: 1rem;
  font-weight: 600;
  text-transform: uppercase;
  text-decoration: none;
  color: white;
  backdrop-filter: blur(6px);
  background: rgba(26, 188, 156, 0.9);
  box-shadow: 0 6px 20px rgba(0, 0, 0, 0.08);
  transition:
    transform 0.25s ease,
    box-shadow 0.3s ease,
    background-color 0.3s ease;
}

.hero-buttons-vertical .cta-button:nth-child(1) {
  grid-column: 1;
}
.hero-buttons-vertical .cta-button:nth-child(2) {
  grid-column: 2;
}
.hero-buttons-vertical .cta-button:nth-child(3) {
  grid-column: 1 / span 2;
  justify-self: center;
}

.cta-button {
  padding: 14px 28px;
  font-size: 1rem;
  font-weight: 600;
  text-transform: uppercase;
  text-align: center;
  border-radius: 999px; /* pill shape */
  text-decoration: none;
  color: white;
  background-color: #1abc9c;
  transition:
    transform 0.2s ease,
    box-shadow 0.3s ease;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
}

.cta-button.primary {
  justify-self: flex-end;
}

.cta-button.secondary {
  background-color: #3498db;
}

.cta-button.tertiary {
  background-color: #e67e22;
}

.cta-button:hover {
  transform: translateY(-2px) scale(1.03);
  box-shadow: 0 8px 20px rgba(0, 0, 0, 0.15);
}

.hero-image {
  flex: 1;
  display: flex;
  justify-content: center;
  align-items: center;
}

.hero-image img {
  max-width: 100%;
  height: auto;
}

/* Video Preview Section */
.video-preview {
  text-align: center;
  margin-top: 20px;
}

.video-preview h2 {
  font-size: 1.8rem;
  margin-bottom: 15px;
  color: #2d3436;
}

/* Modal Overlay */
.modal-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  background-color: rgba(0, 0, 0, 0.8);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;
}

/* Modal Overlay */
.modal-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  background-color: rgba(0, 0, 0, 0.8);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;
}

/* Modal Content */
.modal-content {
  background: white;
  padding: 20px;
  border-radius: 10px;
  box-shadow: 0 4px 10px rgba(0, 0, 0, 0.5);
  position: relative;
  text-align: center;
  width: 80%; /* Adjust width for a larger video viewer */
  max-width: 900px; /* Limit the max width */
  height: auto;
}

.modal-content video {
  border-radius: 10px;
  width: 100%; /* Ensure video scales to the modal content size */
  height: auto;
}

/* Close Button */
.modal-close-button {
  position: absolute;
  top: -15px; /* Move the button slightly above the modal */
  right: -15px; /* Move the button to the right of the modal */
  background: #e74c3c;
  color: white;
  border: none;
  border-radius: 50%;
  width: 40px; /* Make the button slightly larger */
  height: 40px; /* Make the button slightly larger */
  font-size: 1.2rem;
  cursor: pointer;
  display: flex;
  justify-content: center;
  align-items: center;
  transition:
    background-color 0.3s ease,
    transform 0.2s ease;
  box-shadow: 0 4px 10px rgba(0, 0, 0, 0.3); /* Add a subtle shadow */
}

.modal-close-button:hover {
  background: #c0392b;
  transform: scale(1.1); /* Slightly enlarge the button on hover */
}

/* Why Section */
.why-section {
  text-align: center;
  padding: 10px 20px;
  background: #ffffff;
  margin: 90px 0;
  border-radius: 16px;
  box-shadow: 0 12px 32px rgba(0, 0, 0, 0.318);
}

.why-section h2 {
  font-size: 2.75rem;
  color: #2d3436;
  margin-bottom: 50px;
  position: relative;
  display: inline-block;
}

.why-section h2::after {
  content: "";
  width: 50%;
  height: 4px;
  background: linear-gradient(to right, #3498db, #9b59b6);
  position: absolute;
  bottom: -10px;
  left: 25%;
  border-radius: 2px;
}

.why-grid {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  gap: 40px;
  justify-items: center;
  max-width: 1000px;
  margin: 0 auto;
}

.why-item {
  background: #fdfdfd;
  padding: 30px;
  border-radius: 16px;
  box-shadow: 0 8px 24px rgba(0, 0, 0, 0.427);
  transition:
    transform 0.3s ease,
    box-shadow 0.3s ease;
  text-align: center;
}

.why-item:hover {
  transform: translateY(-6px);
  box-shadow: 0 12px 30px rgba(0, 0, 0, 0.1);
}

.why-item.center-last {
  grid-column: 2 / 3;
}

.icon-circle {
  width: 60px;
  height: 60px;
  margin: 0 auto 20px;
  border-radius: 50%;
  background-color: #3498db; /* overridden below */
  display: flex;
  align-items: center;
  justify-content: center;
  color: white;
  font-size: 1.5rem;
  box-shadow: 0 4px 12px rgba(0, 0, 0, 0.08);
  transition: background-color 0.3s ease;
}

.why-item:nth-child(1) .icon-circle {
  background-color: #3498db;
}
.why-item:nth-child(2) .icon-circle {
  background-color: #9b59b6;
}
.why-item:nth-child(3) .icon-circle {
  background-color: #e67e22;
}
.why-item:nth-child(4) .icon-circle {
  background-color: #f1c40f;
  color: #2d3436;
}
.why-item:nth-child(5) .icon-circle {
  background-color: #1abc9c;
}
.why-item:nth-child(6) .icon-circle {
  background-color: #2ecc71;
}
.why-item:nth-child(7) .icon-circle {
  background-color: #f3123b; /* gold-ish */
}

.why-item h3 {
  font-size: 1.4rem;
  margin-bottom: 12px;
  color: #2d3436;
}

.why-item p {
  font-size: 1.05rem;
  color: #636e72;
  line-height: 1.6;
}

/* Call to Action */
.cta-section {
  text-align: center;
  padding: 80px 30px;
  background: linear-gradient(135deg, #3498db, #9b59b6);
  color: white;
  border-radius: 16px;
  margin-top: 80px;
}

.cta-section h2 {
  font-size: 2.5rem;
  font-weight: 800;
  margin-bottom: 20px;
  letter-spacing: -0.5px;
}

.cta-subtext {
  font-size: 1.15rem;
  max-width: 700px;
  margin: 0 auto 40px;
  color: #ecf0f1;
  line-height: 1.6;
}

.cta-buttons {
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
  gap: 15px;
}

.cta-buttons .cta-button {
  background-color: white;
  color: #9b59b6;
  font-weight: 600;
  padding: 14px 28px;
  font-size: 1rem;
  border-radius: 999px;
  text-decoration: none;
  transition:
    background-color 0.3s ease,
    transform 0.2s ease;
}

.cta-buttons .cta-button:hover {
  background-color: #f4f4f4;
  transform: translateY(-2px);
}

.cta-buttons .cta-button.secondary {
  background-color: #2d3436;
  color: white;
}

.cta-buttons .cta-button.secondary:hover {
  background-color: #444;
}
