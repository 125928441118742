/* Membership.css */

/* General Page Styling */
.membership-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 2rem;
    background-color: #f3f4f6;
    min-height: 100vh;
  }
  
  .membership-title {
    font-size: 2.5rem;
    font-weight: bold;
    margin-bottom: 1.5rem;
    text-align: center;
    color: #333;
  }
  
  /* Membership Grid */
  .membership-grid {
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(320px, 1fr));
    gap: 2rem;
    width: 100%;
    max-width: 90rem;
  }
  
  /* Membership Cards */
  .membership-card {
    background-color: white;
    padding: 2rem;
    border-radius: 12px;
    box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.1);
    border: 2px solid #e5e7eb;
    transition: transform 0.3s ease-in-out, box-shadow 0.3s ease-in-out;
    position: relative;
    text-align: center;
  }
  
  .membership-card:hover {
    transform: scale(1.03);
    box-shadow: 0px 6px 12px rgba(0, 0, 0, 0.2);
  }
  
  /* Recommended Badge */
  .recommended {
    border: 3px solid #f39c12;
  }
  
  .recommended-badge {
    position: absolute;
    top: -12px;
    left: 50%;
    transform: translateX(-50%);
    background-color: #f39c12;
    color: white;
    font-weight: bold;
    padding: 6px 12px;
    border-radius: 16px;
    font-size: 0.875rem;
  }
  
  /* Card Content */
  .card-content {
    padding-bottom: 1rem;
  }
  
  .tier-name {
    font-size: 1.5rem;
    font-weight: bold;
    margin-bottom: 0.5rem;
    color: #333;
  }
  
  .tier-description {
    color: #555;
    font-size: 1rem;
    margin-bottom: 1rem;
  }
  
  .membership-features {
    font-size: 0.9rem;
    color: #374151;
    list-style-type: none;
    padding: 0;
  }
  
  .feature-item {
    margin-bottom: 0.5rem;
    padding: 8px;
    background: #f8f9fa;
    border-radius: 6px;
  }
  
  /* Upgrade Button */
  .upgrade-button {
    background-color: #4a90e2;
    color: white;
    font-size: 1rem;
    font-weight: bold;
    padding: 10px 16px;
    border: none;
    border-radius: 8px;
    cursor: pointer;
    transition: background 0.3s ease-in-out;
    width: 100%;
    margin-top: 1rem;
  }
  
  .upgrade-button:hover {
    background-color: #357abd;
  }
  
  .upgrade-button:disabled {
    background-color: #ccc;
    cursor: not-allowed;
  }
  
  .selected {
    background-color: #2ecc71;
  }
  
  /* Feature Comparison Table */
  .membership-table-container {
    margin-top: 2rem;
    width: 100%;
    max-width: 80rem;
    overflow-x: auto;
  }
  
  .membership-table {
    width: 100%;
    border-collapse: collapse;
    border-radius: 8px;
    overflow: hidden;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  }
  
  .membership-table thead {
    background-color: #4a90e2;
    color: white;
  }
  
  .membership-table th,
  .membership-table td {
    padding: 12px 16px;
    text-align: center;
    border-bottom: 1px solid #ddd;
  }
  
  .membership-table th {
    font-weight: bold;
    text-transform: uppercase;
  }
  
  .membership-table tbody tr:nth-child(even) {
    background-color: #f9f9f9;
  }
  
  /* Checkmark and Cross Styling */
  .check {
    color: #2ecc71;
    font-weight: bold;
  }
  
  .cross {
    color: #e74c3c;
    font-weight: bold;
  }
  
  /* Responsive Design */
  @media (max-width: 768px) {
    .membership-table th,
    .membership-table td {
      font-size: 0.875rem;
      padding: 10px;
    }
  
    .membership-title {
        font-size: 2rem;
      }
    
    .recommended-badge {
    font-size: 0.75rem;
    padding: 5px 10px;
    }
    
    .upgrade-button {
    font-size: 0.9rem;
    padding: 8px 14px;
    }
  }
  