.forgot-password-modal-wrapper {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.5);
    display: flex;
    align-items: center;
    justify-content: center;
    z-index: 1000;
  }
  
  .forgot-password-modal {
    position: relative;
    background-color: #fff;
    padding: 20px;
    border-radius: 8px;
    box-shadow: 0 4px 15px rgba(0, 0, 0, 0.2);
    width: 300px;
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 10px;
  }
  
  .forgot-password-modal form {
    display: flex;
    flex-direction: column;
    gap: 10px;
    width: 80%;
  }
  
  .forgot-password-modal input {
    padding: 10px;
    border: 1px solid #ccc;
    border-radius: 4px;
  }
  
  .forgot-password-submit-button {
    background-color: #1abc9c;
    color: white;
    padding: 10px;
    font-size: 16px;
    font-weight: bold;
    border: none;
    border-radius: 4px;
    cursor: pointer;
    transition: background-color 0.3s ease;
  }
  
  .forgot-password-submit-button:hover {
    background-color: #16a085;
  }
  
  .forgot-password-modal-close {
    position: absolute;
    top: 5px;
    right: 5px;
    background: none;
    border: none;
    font-size: 16px;
    font-weight: bold;
    cursor: pointer;
    color: #333;
    padding: 5px;
  }
  
  .forgot-password-modal-close:hover {
    color: #1abc9c;
    background-color: #333;
  }
  
  .forgot-password-message {
    margin-top: 10px;
    color: #333;
  }