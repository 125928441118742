.tutorial-modal {
    position: fixed; /* Fix the position on the screen */
    top: 50%; /* Position it slightly above the bottom edge */
    left: 50%; /* Position it slightly to the right of the left edge */
    background-color: white; /* Background color */
    border: 1px solid #ccc; /* Border style */
    border-radius: 10px; /* Rounded corners */
    padding: 10px 20px; /* Padding inside the modal */
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1); /* Subtle shadow for depth */
    z-index: 9999; /* Ensure it's on top of everything else */
    width: 300px; /* Fixed width */
  }
  
  .tutorial-modal button {
    margin-top: 10px; /* Add spacing above the buttons */
    padding: 5px 10px; /* Add padding for clickability */
    border: none; /* Remove default button borders */
    border-radius: 5px; /* Rounded corners */
    background-color: #3498db; /* Button background color */
    color: white; /* Button text color */
    cursor: pointer; /* Pointer cursor */
  }
  
  .tutorial-modal button:hover {
    background-color: #2980b9; /* Slightly darker color on hover */
  }
  