.learning-paths-wrapper {
  display: flex;
  min-height: 100vh;
  background: linear-gradient(to bottom right, #f3f7fa, #e4ecf3);
  font-family: "Fira Code", monospace;
}

/* === Sidebar === */
.sidebar {
  height: 100%;
  flex-shrink: 0;
  overflow-y: auto;
  display: flex;
  flex-direction: column;
  background-color: #ffffff;
  box-shadow: 2px 0 10px rgba(0, 0, 0, 0.05);
  padding: 30px 20px;
  width: 280px;
}

.sidebar-title {
  font-size: 1.5rem;
  margin-bottom: 20px;
  color: #2c3e50;
  text-align: center;
}

.sidebar-button {
  width: 100%;
  background: none;
  border: none;
  text-align: left;
  padding: 12px 14px;
  font-size: 0.95rem;
  border-radius: 6px;
  cursor: pointer;
  color: #2c3e50;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  transition: background 0.2s ease;
}

.sidebar-button:hover {
  background-color: #e8f4fc;
}

.sidebar-button.active {
  background-color: #3498db;
  color: #ffffff;
  font-weight: bold;
}

.sidebar-item {
  display: flex;
  flex-direction: column;
  margin-bottom: 12px;
  transition: all 0.3s ease;
  position: relative;
}

.sidebar-item:hover .progress-bar-fill {
  opacity: 1;
  font-size: 0.75rem;
  text-shadow: 0 0 6px rgba(255, 255, 255, 0.9);
}

.sidebar-item:hover .progress-label {
  opacity: 1;
  color: #2ecc71;
  font-weight: bold;
}

.sidebar-item:hover .progress-label-inside {
  color: white;
  opacity: 1;
  text-shadow: 0 0 6px rgba(255, 255, 255, 0.9);
}

.sidebar-item:hover .progress-bar-container {
  height: 24px;
}


.progress-bar-container {
  background-color: #e0e0e0;
  border-radius: 4px;
  margin: 6px 14px 12px 14px;
  overflow: hidden;
  height: 6px;
  position: relative;
  transition: height 0.3s ease;
}


.progress-bar-fill {
  height: 100%;
  background: linear-gradient(90deg, #00c9a7, #2ecc71);
  transition: width 0.4s ease-in-out, opacity 0.3s ease;
  border-radius: 4px;
  display: flex;
  align-items: center;
  justify-content: flex-end;
  padding-right: 8px;
  font-size: 0.7rem;
  color: white;
  font-weight: bold;
  opacity: 0.5;
}

.progress-label {
  position: absolute;
  right: 10px;
  bottom: -22px;
  font-size: 0.75rem;
  color: #999;
  opacity: 0;
  transition: opacity 0.3s ease;
  pointer-events: none;
}

.progress-label-inside {
  font-size: 0.7rem;
  color: transparent;
  opacity: 0;
  transition: opacity 0.3s ease, color 0.3s ease;
  pointer-events: none;
}



/* === Content Box === */
.content-box {
  flex: 1;
  height: 100%;
  overflow-y: auto;
  padding: 40px;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
}

.content-title {
  font-size: 2rem;
  color: #2c3e50;
  margin-bottom: 20px;
}

.content-placeholder {
  font-size: 1rem;
  color: #666;
}
