.login-form {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
    margin-left: 10px;
}

.login-header {
    font-size: 18px;
    margin-right: 10px;
}

.login-form-email {
    margin-right: 10px;
}

.login-form-password {
    margin-right: 20px;
}

.login-form-submit {
    margin-right: 20px;
}

.login-form-submit button {
    color: white;
}

.login-form-register {
    margin-right: 10px;
}

.login-form-register a {
    color: white;
    text-decoration: none;
}

.login-form-register a:hover {
    text-decoration: underline;
}

.google-login button {
    color: white;
    cursor: pointer;
    transition: background-color 0.3s ease;
}

.google-login button:hover {
    background-color: #444;
}
