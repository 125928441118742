.animation-wrapper {
  perspective: 800px;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%; /* or a fixed height like 300px if needed */
  padding: 40px 0;
}

.animation-wrapper:hover .grid-container {
  transform: rotateX(0deg) rotateY(0deg) scale(1.02);
}

.grid-container {
  display: grid;
  grid-template-areas:
    "left-stack top-left top-right"
    "left-stack bottom-left bottom-right";
  grid-template-columns: 140px 240px 240px; /* Add a new column */
  grid-template-rows: 240px 240px;
  gap: 16px;
  margin: 0 auto;
  position: relative;
  transform: rotateX(5deg) rotateY(10deg);
  transform-style: preserve-3d;
  transition: transform 0.6s ease;
  will-change: transform;
}

.grid-box {
  background-color: #f2f2f2;
  border-radius: 12px;
  border: 1px solid #dfe6e9;
  position: relative;
  box-shadow: 0 8px 20px rgba(0, 0, 0, 0.605);
  /* padding: 16px; */
  transition: all 0.3s ease;
}

.grid-box:hover {
  background-color: rgba(26, 188, 156, 0.08); /* subtle green tint */
  border-color: #1abc9c;
  box-shadow: 0 6px 18px rgba(26, 188, 156, 0.2);
  transform: translateY(-2px) scale(1.02);
  z-index: 2;
  cursor: pointer;
}


.section-label {
  position: absolute;
  top: 6px;
  left: 10px;
  font-size: 1rem;
  font-family: "Inter", sans-serif;
  font-weight: 600;
  color: #2d3436;
  z-index: 2;
}

/* Grid Areas */
.left-stack {
  grid-area: left-stack;
}
.top-left {
  grid-area: top-left;
}
.top-right {
  grid-area: top-right;
}
.bottom-left {
  grid-area: bottom-left;
}
.bottom-right {
  grid-area: bottom-right;
}

/* Make the "Code" label white inside the code section */
.top-right .section-label {
  color: white !important;
}

.top-right.grid-box:hover .code-editor-shell {
  box-shadow: 0 0 12px #1abc9c, inset 0 0 4px #1abc9c;
  background-color: rgba(30, 30, 30, 0.95);
}


/* Animated Block (Small Square) */
.animated-block {
  width: 30px;
  height: 30px;
  background-color: #1abc9c;
  border-radius: 6px;
  position: absolute;
  left: 0;
  z-index: 10;
}

/* Long Code Block */
.long-block {
  width: 60px;
  height: 20px;
  /* background-color: #3498db; */
  border-radius: 4px;
  position: absolute;
  top: 40px;
  left: 20px;
  z-index: 10;
}

/* Success Icon */
.success-icon {
  position: absolute;
  top: 30%;
  left: 30%;
  transform: translate(-50%, -50%);
  color: #1abc9c;
  z-index: 20;
}

.floating-block {
  width: 36px;
  height: 36px;
  background-color: #1abc9c;
  border-radius: 6px;
  position: absolute;
  z-index: 10;
}

.floating-block.blue {
  background-color: #3498db;
}

.floating-block.yellow {
  background-color: #f1c40f;
  width: 44px;
  height: 44px;
}


.problem-placeholder {
  padding: 12px;
  margin-top: 20px;
  display: flex;
  flex-direction: column;
  gap: 8px;
  height: 90%;
  overflow: hidden;
  justify-content: space-evenly; /* Even out spacing top to bottom */
}


.gray-box {
  background-color: #dfe6e9;
  border-radius: 6px;
  animation: pulseFlow 1.6s infinite ease-in-out;
}

.title-box {
  height: 20px;
  width: 80%;
}

.line-box {
  height: 12px;
  width: 90%;
}

.line-box.short {
  width: 60%;
}

.example-header {
  height: 16px;
  width: 70%;
  margin-top: 12px;
}

.input-box,
.output-box {
  height: 14px;
  width: 85%;
  margin-left: 8px;
}

.shade-1 {
  background-color: #dfe6e9;
}
.shade-2 {
  background-color: #dcdde1;
}
.shade-3 {
  background-color: #ced6e0;
}
.shade-4 {
  background-color: #b2bec3;
}


@keyframes pulseFlow {
  0% {
    opacity: 0.7;
  }
  50% {
    opacity: 0.4;
  }
  100% {
    opacity: 0.7;
  }
}


.code-text {
  font-family: "Fira Code", monospace;
  color: white;
  font-size: 0.65rem;
  padding-left: 2px;
  line-height: 10px;
  text-wrap: nowrap;
}

.code-editor-shell {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: #1e1e1e;
  border-radius: 8px;
  overflow: hidden;
  box-shadow:
    inset 0 0 0 1px #333,
    0 4px 12px rgba(0, 0, 0, 0.2);
  display: flex;
  flex-direction: column;
  z-index: 0;
}

.code-editor-body {
  flex: 1;
  padding: 6px;
  font-family: "Fira Code", monospace;
  font-size: 0.8rem;
  color: #d4d4d4;
}

.code-line {
  font-family: "Fira Code", monospace;
  font-size: 0.6rem;
  line-height: 1.5;
  white-space: nowrap;
  color: #d4d4d4;
}

.line-number {
  color: #6a9955;
  padding-right: 6px;
  font-weight: 300;
}

.code-keyword {
  color: #569cd6;
}

.code-variable {
  color: #9cdcfe;
}

.code-string {
  color: #ce9178;
}

.code-method {
  color: #dcdcaa;
}

.code-function {
  color: #dcdcaa;
}
