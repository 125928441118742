/* LogoutButton.css */
.logout-button {
    background-color: #c93f2f;
    color: white;
    padding: 8px 16px;
    border-radius: 4px;
    border: none;
    font-size: 14px;
    cursor: pointer;
    transition: background-color 0.3s ease;
  }
  
  .logout-button:hover {
    background-color: #992e22;
  }
  