/* Problem Section (Dark Mode to Match LeetCode UI) */
.new-problem-section {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    padding: 10px;
    width: 100%;
    height: 100%;
    overflow-y: auto; /* Allow scrolling only if necessary */
    background-color: #252526; /* Dark theme */
    border-right: 2px solid #3c3c3c; /* Subtle border */
    font-family: "Fira Code", monospace;
    color: #d4d4d4; /* Light text */
  }
  
  /* Problem Metadata */
  .problem-metadata {
    display: flex;
    /* flex-direction: column; */
    align-items: center;
    justify-content: flex-start;
    gap: 10px;
    width: 100%;
    margin-bottom: 5px;
  }
  
  /* Problem Title */
  .new-problem-title {
    font-size: 20px;
    font-weight: bold;
    color: #ffffff;
    margin-bottom: 5px;
  }
  
  /* Problem Level */
  .new-problem-level {
    font-size: 14px;
    font-weight: bold;
    color: #1abc9c; /* Highlight color */
    background-color: #3c3c3c;
    padding: 4px 10px;
    border-radius: 4px;
  }
  
  /* Problem Description */
  .new-problem-description {
    font-size: 13px;
    line-height: 1.5;
    color: #d4d4d4;
    background: #1e1e1e;
    padding: 10px;
    border-radius: 5px;
    width: 80%;
    max-width: 340px;
    box-shadow: 0 2px 5px rgba(255, 255, 255, 0.1);
  }
  
  /* Example & Constraints Sections */
  .new-examples-section,
  .new-constraints-section {
    margin-top: 10px;
    padding: 5px;
    /* background: #2d2d2d; */
    border-radius: 5px;
    box-shadow: 0 2px 5px rgba(255, 255, 255, 0.1);
    width: 90%;
    max-width: 350px;
  }
  
  /* Section Headings */
  .new-examples-section h3,
  .new-constraints-section h3 {
    font-size: 16px;
    margin-bottom: 8px;
    color: #ffffff;
  }
  
  /* Example Card */
  .new-example-card {
    /* background: #1e1e1e; */
    padding: 10px;
    margin-bottom: 5px;
    border-left: 4px solid #ffaa00; /* Highlight */
    border-radius: 5px;
    font-size: 14px;
    font-family: "Fira Code", monospace;
  }
  
  .new-example-title {
    font-family: "Fira Code", monospace;
    font-size: 13px;
    font-weight: bold;
    color: #d4d4d4;
  }
  
  /* Example Content */
  .new-example-content {
    font-family: "Fira Code", monospace;
    color: #d4d4d4;
    background: #333;
    padding: 2px;
    border-radius: 3px;
  }
  
  /* Constraints List */
  .new-constraints-list {
    list-style-type: none;
    background: #2d2d2d;
    padding: 2px;
    margin: 0;
  }
  
  /* Individual Constraint Item */
  .new-constraint-item {
    padding: 5px;
    font-size: 13px;
    border-bottom: 1px solid #444;
    color: #d4d4d4;
  }
  
  /* Remove last border */
  .constraint-item:last-child {
    border-bottom: none;
  }

  ::-webkit-scrollbar {
    width: 8px;
    height: 8px;
  }
  
  ::-webkit-scrollbar-track {
    background: #1e1e1e; /* Dark background for the track */
    border-radius: 10px;
  }
  
  ::-webkit-scrollbar-thumb {
    background: #555; /* Slightly lighter thumb */
    border-radius: 10px;
    transition: background 0.3s;
  }
  
  ::-webkit-scrollbar-thumb:hover {
    background: #777; /* Brighter thumb on hover */
  }