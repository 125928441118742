.contact-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    min-height: 100vh;
    background-color: #f5f5f5;
    padding: 20px;
  }
  
  .contact-heading {
    font-size: 2rem;
    color: #333;
    margin-bottom: 20px;
    font-family: "Fira Code", monospace;
  }
  
  .contact-form {
    display: flex;
    flex-direction: column;
    gap: 15px;
    background-color: white;
    padding: 20px;
    border-radius: 10px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
    width: 100%;
    max-width: 400px;
  }
  
  .contact-label {
    font-size: 1rem;
    color: #555;
    font-weight: bold;
  }
  
  .contact-input {
    padding: 10px;
    font-size: 1rem;
    border-radius: 5px;
    border: 1px solid #ccc;
    width: 100%;
    box-sizing: border-box;
  }
  
  .contact-dropdown {
    padding: 10px;
    font-size: 1rem;
    border-radius: 5px;
    border: 1px solid #ccc;
    width: 100%;
    box-sizing: border-box;
  }
  
  .contact-textarea {
    padding: 10px;
    font-size: 1rem;
    border-radius: 5px;
    border: 1px solid #ccc;
    width: 100%;
    height: 100px;
    box-sizing: border-box;
  }
  
  .contact-button {
    padding: 10px;
    font-size: 1rem;
    border-radius: 5px;
    border: none;
    background-color: #333;
    color: white;
    cursor: pointer;
    font-weight: bold;
    text-align: center;
  }
  