.register-component-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    min-height: 100vh;
    background-color: #f5f5f5;
    padding: 10px;
  }
  
  .register-header {
    font-size: 1.4rem;
    color: #333;
    margin-bottom: 10px;
    font-family: "Fira Code", monospace;
  }
  
  .register-form {
    display: flex;
    flex-direction: column;
    gap: 15px;
    background-color: white;
    padding: 20px;
    border-radius: 10px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
    width: 100%;
    max-width: 400px;
  }
  
  .register-form label {
    font-size: 1rem;
    color: #555;
    font-weight: bold;
  }
  
  .register-form input {
    padding: 10px;
    font-size: 1rem;
    border-radius: 5px;
    border: 1px solid #ccc;
    width: 100%;
    box-sizing: border-box;
    margin-top: 5px;
  }
  
  .register-form select {
    padding: 10px;
    font-size: 1rem;
    border-radius: 5px;
    border: 1px solid #ccc;
    width: 100%;
    box-sizing: border-box;
    margin-top: 5px;
  }
  
  .register-form-submit {
    display: flex;
    justify-content: center;
    margin-top: 20px;
  }
  
  .register-submit-button {
    padding: 10px;
    font-size: 1rem;
    border-radius: 5px;
    border: none;
    background-color: #1abc9c;
    color: white;
    cursor: pointer;
    font-weight: bold;
    text-align: center;
    font-family: "Fira Code", monospace;
  }

  .register-submit-button:hover {
    background-color: #16a085;
  }