.level-switcher-wrapper {
    display: flex;
    align-items: center;
    gap: 10px;
    position: relative;
  }
  
  .slider-button {
    padding: 8px 14px;
    font-size: 0.9rem;
    font-weight: bold;
    border-radius: 6px;
    border: 2px solid transparent;
    background-color: transparent;
    color: #f1f5f9;
    cursor: pointer;
    transition: background-color 0.3s ease, border-color 0.3s ease;
    position: relative;
  }
  
  
  .slider-button.active {
    border-color: #3b5de8;
    background-color: rgba(59, 93, 232, 0.2); /* translucent blue */
    box-shadow: 0 0 4px rgba(59, 93, 232, 0.4);
  }
  
  .slider-button.level-complete {
    background: linear-gradient(45deg, gold, goldenrod);
    color: #1e1e1e;
    font-weight: bold;
    /* border-color: goldenrod; */
    box-shadow: 0 0 6px gold;
  }
  
  /* Optional override to keep the text readable on gold fill
  .intermediate-progress-wrapper .slider-button.active {
    color: #ffffff;
  } */
  
  .slider-button:hover {
    background-color: #dbeafe;
  }
  
  .intermediate-dropdown-wrapper {
    position: relative;
  }
  
  .solution-dropdown {
    position: absolute;
    top: 110%;
    left: 0;
    background-color: #1e1e1e;
    border: 1px solid #3c3c3c;
    border-radius: 8px;
    padding: 6px;
    z-index: 10;
    display: flex;
    flex-direction: column;
    min-width: 160px; /* ✅ Ensures dropdown has a clean width */
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.3);
  }
  
  .solution-tab-dropdown {
    background-color: #2e3a59;
    color: #f1f5f9;
    padding: 8px 12px;
    border: none;
    border-radius: 6px;
    font-size: 14px;
    font-family: "Fira Code", monospace;
    text-align: left;
    width: 100%; /* ✅ Makes each button span full dropdown width */
    cursor: pointer;
    transition: background-color 0.2s ease;
    white-space: nowrap;
  }
  
  .solution-tab-dropdown:hover {
    background-color: #3b4a6b;
  }
  
  .solution-tab-dropdown.active {
    background-color: #475569;
    font-weight: bold;
  }
  
  /* .slider-button.level-complete {
    background-color: gold;
    box-shadow: 0 0 5px gold;
  } */

  .intermediate-progress-wrapper {
    position: relative;
    display: inline-block;
    width: fit-content;
  }
  
  /* The fill bar using ::before */
  .intermediate-progress-wrapper::before {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    height: 100%;
    width: var(--fill-percentage, 0%);
    background: linear-gradient(to right, gold, goldenrod);
    border-radius: 6px;
    z-index: 0;
    transition: width 0.3s ease;
  }
  
  /* Keep button content on top of the fill */
  .intermediate-progress-wrapper .slider-button {
    position: relative;
    z-index: 1;
    background-color: transparent;
    color: #f1f5f9;
    /* border: 2px solid #3b4a6b; */
  }

  .intermediate-progress-wrapper .slider-button:hover {
    background-color: #dbeafe;
  }
 
  
/* Make selected Intermediate more visible */
.intermediate-progress-wrapper .slider-button.active {
    border-color: #3b5de8;
    background-color: rgba(59, 93, 232, 0.2);
  }
  
  /* Change text color to black when fully complete */
.slider-button.intermediate-complete {
    color: #1e1e1e;
    font-weight: bold;
  }
  
  