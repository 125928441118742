.big-o-notation-view {
    /* background-color: #f9fafb; */
    color: #1a1a1a;
    border-radius: 16px;
    padding: 30px 40px;
    margin: 20px auto;
    max-width: 600px;
    border: 1px solid #e2e8f0;
    box-shadow: 0 4px 14px rgba(0, 0, 0, 0.514);
    animation: fadeIn 0.3s ease;
    transition: all 0.3s ease;
  }
  
  .optimal-info {
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 5px;
    border-radius: 12px;
    margin-bottom: 5px;
  }
  
  .optimal-text,
  .non-optimal-text {
    font-size: 16px;
    display: flex;
    margin-bottom: 20px;
  }
  
  .optimal-text {
    color: #22c55e; /* green-500 */
    font-weight: bold;
  }
  
  .non-optimal-text {
    color: #f59e0b; /* amber-500 */
    font-weight: bold;
  }
  
  .complexity-info {
    display: flex;
    justify-content: space-between;
    gap: 20px;
    background-color: #ffffff;
    padding: 20px;
    border-radius: 12px;
    border: 1px solid #e5e7eb;
  }
  
  .complexity-card {
    flex: 1;
    padding: 10px 15px;
    background: #f1f5f9;
    border-radius: 10px;
    box-shadow: inset 0 0 4px rgba(0, 0, 0, 0.05);
  }
  
  .complexity-card h4 {
    margin: 0;
    font-size: 14px;
    color: #475569;
    margin-bottom: 10px;
    text-transform: uppercase;
    letter-spacing: 0.5px;
    text-align: center;
  }
  
  .complexity-value {
    font-size: 18px;
    font-weight: bold;
    color: #3498db; /* blue-600 */
    text-align: center;
  }
  
  .big-o-buttons {
    display: flex;
    justify-content: center;
    gap: 20px;
    margin-top: 30px;
  }
  
  .big-o-action-button {
    background-color: #3498db;
    color: white;
    padding: 10px 16px;
    border: none;
    border-radius: 8px;
    font-size: 14px;
    font-weight: bold;
    cursor: pointer;
    transition: background-color 0.25s ease, transform 0.1s ease;
    font-family: "Fira Code", monospace;
    box-shadow: 0 2px 8px rgba(37, 99, 235, 0.2);
  }
  
  .big-o-action-button:hover {
    background-color: #1e40af;
    transform: translateY(-2px);
  }
  
  @keyframes fadeIn {
    from {
      opacity: 0;
      transform: translateY(10px);
    }
    to {
      opacity: 1;
      transform: translateY(0);
    }
  }

  .swal2-popup.big-o-popup {
    border-radius: 12px;
    font-family: 'Inter', sans-serif;
    box-shadow: 0 4px 14px rgba(0, 0, 0, 0.1);
  }
  
  .swal2-popup.big-o-popup code {
    background-color: #e2e8f0;
    color: #1e293b;
    padding: 2px 5px;
    border-radius: 4px;
    font-family: 'Fira Code', monospace;
    font-size: 13px;
  }

  .big-o-action-button.loading {
    opacity: 0.7;
    pointer-events: none;
  }
  
  .big-o-action-button.loading::after {
    content: "";
    margin-left: 8px;
    display: inline-block;
    width: 14px;
    height: 14px;
    border: 2px solid #ccc;
    border-top-color: #2ecc71;
    border-radius: 50%;
    animation: spin 0.6s linear infinite;
  }
  
  @keyframes spin {
    to {
      transform: rotate(360deg);
    }
  }

    /* Pulse animation */
  .pulse {
    animation: pulse 1.2s infinite;
  }
  
  @keyframes pulse {
    0% {
      transform: scale(1);
      box-shadow: 0 0 0px rgba(46, 204, 113, 0.6);
    }
    50% {
      transform: scale(1.05);
      box-shadow: 0 0 10px rgba(46, 204, 113, 0.9);
    }
    100% {
      transform: scale(1);
      box-shadow: 0 0 0px rgba(46, 204, 113, 0.6);
    }
  }
  
  
  