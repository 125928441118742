.path-container {
  width: 40vw;
}

.path-list {
  display: flex;
  flex-direction: column;
  gap: 40px;
}

.difficulty-column {
  width: 100%;
}

.difficulty-heading {
  font-size: 1.4rem;
  font-weight: bold;
  margin-bottom: 15px;
  border-left: 5px solid #bbb;
  padding-left: 10px;
  color: #333;
}

.difficulty-heading.easy {
  border-color: #7ed957;
}
.difficulty-heading.medium {
  border-color: #ffbd44;
}
.difficulty-heading.hard {
  border-color: #f55151;
}

.problem-list {
  list-style: none;
  padding: 0;
  margin: 0;
}

/* Glowy border for full completion */
.problem-list-item.both-complete {
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 14px 20px;
  margin-bottom: 12px;
  background: #fff;  border-radius: 12px;
  transition: all 0.2s ease;
  cursor: pointer;
  border: 2px solid rgb(0, 255, 30);
  box-shadow: 0 0 10px rgba(255, 215, 0, 0.6), 0 0 20px rgba(255, 215, 0, 0.4);
  background: #7ed957;
}

.problem-list-item {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 14px 20px;
  margin-bottom: 12px;
  background: #fff;
  border-radius: 12px;
  box-shadow: 0 4px 10px rgba(0, 0, 0, 0.05);
  transition: all 0.2s ease;
  cursor: pointer;
}

.problem-list-item:hover {
  background-color: #f2f9ff;
  transform: translateY(-2px);
}

.problem-title-row {
  display: flex;
  gap: 10px;
  align-items: center;
}

.problem-index {
  font-weight: bold;
  color: #999;
}

.problem-title {
  color: #2c3e50;
  font-size: 1rem;
  margin-right: 20px;
}

.completion-labels {
  display: flex;
  gap: 12px;
  margin-top: 8px;
  font-size: 0.9rem;
  font-weight: 500;
}

.label {
  padding: 4px 8px;
  border-radius: 6px;
  background-color: #f1f1f1;
  color: #555;
}

.label.complete {
  background-color: #e0f8e9;
  color: #27ae60;
  font-weight: bold;
}

.label.incomplete {
  background-color: #f6f6f6;
  color: #aaa;
}

.mode-button {
  padding: 6px 12px;
  font-size: 0.85rem;
  border: none;
  border-radius: 20px;
  cursor: pointer;
  font-family: "Fira Code", monospace;
  transition: background-color 0.2s ease;
}

.mode-button.complete {
  background-color: #e0f8e9;
  color: #27ae60;
  font-weight: bold;
}

.mode-button.incomplete {
  background-color: #f6f6f6;
  color: #aaa;
}

.mode-button:hover {
  opacity: 0.85;
}
