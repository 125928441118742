/* LoginModal.css */
.login-modal-wrapper {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.5);
    display: flex;
    align-items: center;
    justify-content: center;
    z-index: 1000;
  }

.login-modal {
    position: relative;
    background-color: #fff;
    padding: 20px;
    border-radius: 8px;
    box-shadow: 0 4px 15px rgba(0, 0, 0, 0.2);
    width: 300px;
    justify-items: center;
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 10px;
  }
  
  .login-modal form {
    display: flex;
    flex-direction: column;
    gap: 10px;
    justify-items: left;
    align-items: left;
    width: 80%;
  }

  
  .login-modal input {
    margin-left: 10px;
    padding: 10px;
    font-size: 14px;
    border: 1px solid #ccc;
    border-radius: 4px;
    transition: border-color 0.3s ease;
  }
  
  .login-modal input:focus {
    border-color: #1abc9c;
    outline: none;
  }
  
  .login-submit-button {
    background-color: #1abc9c;
    color: white;
    padding: 10px;
    font-size: 16px;
    font-weight: bold;
    border: none;
    border-radius: 4px;
    cursor: pointer;
    transition: background-color 0.3s ease;
  }
  
  .login-submit-button:hover {
    background-color: #16a085;
  }
  
  .login-modal-close {
    position: absolute;
    top: 5px;
    right: 5px;
    background: none;
    border: none;
    font-size: 16px;
    font-weight: bold;
    cursor: pointer;
    color: #333;
    padding: 5px;
  }
  
  .login-modal-close:hover {
    color: #1abc9c;
    background-color: #333;
  }
  
  .google-login {
    background-color: #3498DB;
    color: white;
    padding: 8px 16px;
    border-radius: 4px;
    font-size: 16px;
    font-weight: bold;
    text-decoration: none;
    cursor: pointer;
    transition: background-color 0.3s ease;
    margin-left: 10px;
  }
  
  .google-login:hover {
    background-color: #357ae8;
  }

  .register-button {
    background-color: #1abc9c;
    color: white;
    padding: 10px;
    font-size: 16px;
    font-weight: bold;
    border: none;
    border-radius: 4px;
    cursor: pointer;
    transition: background-color 0.3s ease;
  }
  
  .register-button:hover {
    background-color: #16a085;
  }

  .or-text{
    color:#333;
    text-decoration: underline solid;
    font-weight: bold;
    margin: 5px;
  }