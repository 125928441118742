body {
  background: linear-gradient(to bottom right, #f3f7fa, #e4ecf3);
  color: #333;
  font-family: "Inter", sans-serif;
  margin: 0;
  padding: 0;
}

.problems-list-container {
  padding: 20px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

/* === Header === */
.header-title-bar {
  text-align: center;
  /* margin-bottom: 30px; */
  /* padding: 30px 20px; */
  /* background: #ffffff;
  border-radius: 12px;
  box-shadow: 0 4px 16px rgba(0, 0, 0, 0.05); */
  /* border: 1px solid #e0e6ed; */
}

.header-title {
  font-size: 2.25rem;
  font-weight: 600;
  color: #2c3e50;
  margin-bottom: 10px;
  letter-spacing: -0.5px;
}

.header-subtitle {
  font-size: 1rem;
  color: #6c7a89;
  font-weight: 400;
  margin-top: 5px;
}

.condensed-header-controls {
  flex-wrap: nowrap;
  justify-content: space-between;
  width: 100%;
  max-width: 900px;
  margin: 0 auto 20px auto;
  gap: 16px;
}

.condensed-header-controls .condensed {
  flex: 1;
  min-width: 0;
  white-space: nowrap;
}

/* Prevent search bar from growing too wide */
.search-bar.condensed {
  max-width: 350px;
}

/* Responsive fallback */
@media (max-width: 768px) {
  .condensed-header-controls {
    flex-direction: column;
    align-items: stretch;
  }

  .condensed-header-controls .condensed {
    width: 100%;
  }
}

.learning-path-cta {
  margin-top: 20px;
  background: linear-gradient(90deg, #e0f7fa, #e8f5e9);
  border: 1px solid #d0e6ea;
  border-radius: 12px;
  padding: 14px 20px;
  max-width: 420px;
  cursor: pointer;
  transition: transform 0.2s ease, box-shadow 0.2s ease;
  box-shadow: 0 4px 10px rgba(0, 0, 0, 0.03);
}

.learning-path-cta:hover {
  transform: translateY(-2px);
  box-shadow: 0 6px 16px rgba(0, 0, 0, 0.06);
}

.cta-content {
  display: flex;
  align-items: center;
  gap: 16px;
}

.cta-icon {
  font-size: 1.8rem;
}

.cta-text {
  flex: 1;
}

.cta-text strong {
  display: block;
  color: #2c3e50;
  font-size: 1rem;
  margin-bottom: 2px;
}

.cta-text p {
  margin: 0;
  font-size: 0.9rem;
  color: #5d6d7e;
}

.cta-arrow {
  font-size: 1.4rem;
  color: #3498db;
}


.learning-path-link {
  color: #3498db;
  font-weight: 500;
  text-decoration: underline;
  cursor: pointer;
  transition: color 0.2s ease;
}

.learning-path-link:hover {
  color: #1abc9c;
}


.header-controls {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  gap: 12px;
  margin-top: 15px;
  margin-bottom: 15px;
}

.search-bar {
  background: #ffffff;
  border: 1px solid #ccc;
  color: #333;
  border-radius: 8px;
  padding: 10px;
  width: 100%;
  max-width: 400px;
  transition: box-shadow 0.2s ease;
}

.search-bar:focus {
  box-shadow: 0 0 10px rgba(52, 152, 219, 0.3);
  border-color: #3498db;
}

.filter-group {
  display: flex;
  gap: 10px;
}

.filter-dropdown {
  background: #fff;
  color: #333;
  border: 1px solid #ccc;
  border-radius: 8px;
  padding: 10px;
  font-size: 1rem;
  transition: border-color 0.2s ease-in-out;
}

.filter-dropdown:focus {
  border-color: #3498db;
  box-shadow: 0 0 8px rgba(52, 152, 219, 0.3);
}

/* === Problems Container === */
.problems-container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  /* align-items: center; */
  gap: 20px;
  width: 70vw;
}

/* === Problem Cards === */
.problem-wrapper {
  position: relative;
  border-radius: 12px;
  overflow: hidden;
}

.problem-row {
  display: flex;
  justify-content: space-between;
  align-items: center;
  background: #ffffff;
  padding: 20px;
  border-radius: 12px;
  box-shadow: 0 4px 12px rgba(0, 0, 0, 0.08);
  transition: transform 0.3s ease, box-shadow 0.3s ease;
}

.problem-row:not(.locked):hover {
  transform: scale(1.02);
  box-shadow: 0 10px 24px rgba(0, 0, 0, 0.08);
  background: #f9fcff;
}

.problem-title {
  font-size: 1.5rem;
  font-weight: bold;
  color: #2c3e50;
  margin: 0 0 5px;
}

.problem-solved {
  background: linear-gradient(45deg, #ffd700, #ffb300);
  box-shadow:
    0 0 8px rgba(255, 215, 0, 0.5),
    0 0 15px rgba(255, 200, 0, 0.5),
    0 0 30px rgba(255, 190, 0, 0.35);
  border: 1px solid #f7d200;
  transition: all 0.3s ease-in-out;
}

.problem-solved:hover {
  background: linear-gradient(45deg, #fff176, #ffca28) !important;
  box-shadow:
    0 0 10px rgba(255, 215, 0, 0.7),
    0 0 20px rgba(255, 200, 0, 0.6),
    0 0 35px rgba(255, 190, 0, 0.5);
  transform: scale(1.035);
}



/* === Difficulty Badges === */
.difficulty {
  display: inline-block;
  font-size: 0.75rem;
  padding: 5px 10px;
  border-radius: 12px;
  font-weight: bold;
  margin-right: 8px;
  text-transform: uppercase;
}

.difficulty.easy {
  background-color: #58d68d;
  color: #fff;
}
.difficulty.medium {
  background-color: #f7dc6f;
  color: #000;
}
.difficulty.hard {
  background-color: #ec7063;
  color: #fff;
}

/* === Category Pill === */
.category-pill {
  display: inline-block;
  padding: 5px 10px;
  border-radius: 12px;
  background-color: #d6dbdf;
  color: #2c3e50;
  font-size: 0.75rem;
  text-transform: capitalize;
}

/* === Level Buttons === */
.level-buttons-container {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.level-buttons-row {
  display: flex;
  align-items: center;
  gap: 10px;
}

.block-mode-group,
.code-mode-group {
  display: flex;
  gap: 8px;
}

.level-button {
  padding: 10px 16px;
  font-size: 0.85rem;
  border-radius: 8px;
  border: none;
  font-weight: bold;
  cursor: pointer;
  color: white;
  text-transform: uppercase;
  transition: transform 0.2s ease, box-shadow 0.2s ease;
}

.level-button:hover {
  transform: translateY(-1px);
  box-shadow: 0 4px 12px rgba(0, 0, 0, 0.1);
}

.level-button.beginner {
  background-color: #1abc9c;
}
.level-button.intermediate {
  background-color: #3498db;
}
.level-button.expert {
  background-color: #e67e22;
}
.level-button.pro {
  background-color: #f44336;
}

.level-gold {
  background: linear-gradient(45deg, #ffd700, #ffb400);
  color: black;
  border: 2px solid #ffc107;
  box-shadow: 0 4px 15px rgba(255, 215, 0, 0.4);
  transform: scale(1.05);
}

.level-gold:hover {
  background: linear-gradient(45deg, #ffea00, #ffc400);
  box-shadow: 0 6px 20px rgba(255, 223, 0, 0.7);
}

/* === Mode Labels & Divider === */
.mode-divider {
  height: 40px;
  width: 2px;
  background: #bbb;
  margin: 0 12px;
}

.mode-labels {
  display: flex;
  justify-content: space-between;
  width: 100%;
  position: relative;
  margin-top: 8px;
}

.mode-label {
  font-size: 0.75rem;
  font-weight: bold;
  color: #555;
  text-align: center;
  position: absolute;
  white-space: nowrap;
}

.block-label {
  left: 50%;
  transform: translateX(-145%); /* Center between beginner & intermediate */
}

.code-label {
  right: 0;
  transform: translateX(-10%); /* Nudge left under Pro */
}


/* === Lock Overlay === */
.locked {
  filter: blur(1px);
  opacity: 0.6;
  pointer-events: none;
}

.lock-overlay {
  position: absolute;
  inset: 0;
  background: rgba(255, 255, 255, 0.75);
  backdrop-filter: blur(4px);
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  z-index: 2;
  text-align: center;
  padding: 10px;
}

.lock-icon {
  font-size: 28px;
  margin-bottom: 6px;
  color: #2c3e50;
}

.lock-message {
  font-size: 0.85rem;
  font-weight: 500;
  color: #2c3e50;
}

.upgrade-inline-button {
  background: #f39c12;
  color: white;
  padding: 6px 12px;
  font-size: 0.75rem;
  border: none;
  border-radius: 6px;
  font-weight: bold;
  margin-top: 5px;
  cursor: pointer;
  transition: background-color 0.2s ease;
}

.upgrade-inline-button:hover {
  background-color: #e67e22;
}

/* === Loading Spinner === */
.loading-container {
  text-align: center;
  margin-top: 40px;
  font-size: 1rem;
  color: #555;
}

/* === Responsive === */
@media (max-width: 768px) {
  .problem-row {
    flex-direction: column;
    align-items: flex-start;
    gap: 15px;
  }

  .level-buttons-row {
    flex-wrap: wrap;
    justify-content: center;
  }

  .level-button {
    width: 100%;
    text-align: center;
  }
}
