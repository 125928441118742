.bug-report-overlay {
    position: fixed;
    top: 0;
    left: 0;
    width: 100vw;
    height: 100vh;
    background-color: rgba(0, 0, 0, 0.5); /* Transparent black overlay */
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 1000;
  }
  
  .bug-report-form {
    background-color: white;
    padding: 20px;
    border-radius: 8px;
    width: 400px;
    box-shadow: 0 4px 15px rgba(0, 0, 0, 0.2);
  }
  
  .readonly-input {
    width: 100%;
    padding: 8px;
    margin-bottom: 15px;
    border: 1px solid #ccc;
    border-radius: 5px;
    background-color: #f9f9f9;
  }
  
  textarea {
    width: 100%; /* Ensure it fits perfectly within the container */
    max-width: 100%; /* Prevent it from exceeding the container's width */
    height: 100px; /* Adjust height to a reasonable size */
    padding: 10px; /* Add some internal padding */
    font-family: "Fira Code", monospace;
    font-size: 14px;
    border: 1px solid #ccc;
    border-radius: 5px;
    resize: none; /* Disable resizing altogether */
    box-sizing: border-box; /* Include padding and border in total width */
  }
  
  textarea:focus {
    border-color: #1abc9c; /* Match theme color on focus */
    outline: none; /* Remove default focus outline */
  }
  
  
  .bug-report-form {
    max-width: 500px; /* Restrict the width of the form */
    width: 90%; /* Ensure responsiveness */
    margin: 0 auto; /* Center the form horizontally */
    padding: 20px;
    border-radius: 10px;
    background-color: #fff;
    box-shadow: 0 4px 10px rgba(0, 0, 0, 0.2);
  }
  
  .button-container {
    display: flex;
    justify-content: space-between;
    margin-top: 20px;
  }
    
  .submit-button {
    background-color: #1abc9c;
    color: white;
    border: none;
    border-radius: 5px;
    padding: 10px 15px;
    cursor: pointer;
  }
  
  .cancel-button {
    background-color: #e74c3c;
    color: white;
    border: none;
    border-radius: 5px;
    padding: 10px 15px;
    cursor: pointer;
  }
  
  .submit-button:hover {
    background-color: #16a085;
  }
  
  .cancel-button:hover {
    background-color: #c0392b;
  }
  .bug-report-message {
    margin: 10px 0;
    padding: 10px;
    border-radius: 5px;
    text-align: center;
    font-weight: bold;
  }
  
  .bug-report-message.success {
    background-color: #d4edda; /* Light green */
    color: #155724; /* Dark green */
  }
  
  .bug-report-message.error {
    background-color: #f8d7da; /* Light red */
    color: #721c24; /* Dark red */
  }
  .form-row {
    display: flex;
    flex-direction: column;
    margin-bottom: 15px; /* Add some space between rows */
  }
  select {
    width: 100%; /* Ensure dropdown takes full width */
    padding: 8px;
    border-radius: 5px;
    border: 1px solid #ccc;
    font-size: 14px;
  }