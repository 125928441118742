/* Fullscreen loading background */
.loading-screen {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: #1e1e1e;
  color: #00ff9d;
  display: flex;
  flex-direction: column; /* Stack items vertically */
  justify-content: center;
  align-items: center;
  font-family: 'Courier New', Courier, monospace;
  z-index: 9999;
}

/* Terminal-style container */
.loading-terminal {
  width: 80%;
  max-width: 600px;
  padding: 20px;
  background-color: #262626;
  border: 3px solid #00ff9d;
  border-radius: 10px;
  box-shadow: 0px 0px 15px rgba(0, 255, 157, 0.3);
  text-align: center;
  margin-bottom: 20px; /* Add spacing below the terminal */
}

/* Animated dots */
.dots {
  animation: blink 1.2s steps(3, start) infinite;
}

/* Blinking cursor effect */
@keyframes blink {
  0%, 100% {
      opacity: 0;
  }
  50% {
      opacity: 1;
  }
}

/* Loading message styling */
.loading-message {
  font-size: 18px;
  color: #00ff9d;
  margin: 10px 0;
}

/* Styles for the browse message container */
.browse-message {
  margin-top: 20px;
  text-align: center;
  color: #f0f0f0; /* Light gray for visibility */
  font-size: 1.2rem; /* Slightly larger font for readability */
  line-height: 1.5; /* Improve readability with spacing */
  max-width: 600px; /* Limit the width for better appearance */
}

/* Styles for the browse button */
.browse-problems-button {
  display: inline-block;
  margin-top: 10px;
  padding: 10px 20px; /* Padding for better click area */
  background-color: #007bff; /* Blue background for the button */
  color: #ffffff; /* White text for contrast */
  border: none; /* Remove default border */
  border-radius: 8px; /* Rounded corners for a modern look */
  font-size: 1rem; /* Font size for readability */
  font-weight: bold; /* Make text stand out */
  cursor: pointer; /* Show pointer cursor on hover */
  transition: background-color 0.3s ease, transform 0.2s ease; /* Smooth hover effects */
}

/* Hover effect for the button */
.browse-problems-button:hover {
  background-color: #0056b3; /* Darker blue on hover */
  transform: translateY(-2px); /* Slight lift effect */
}

/* Active state for the button */
.browse-problems-button:active {
  background-color: #004494; /* Even darker blue when clicked */
  transform: translateY(0); /* Reset lift effect */
}

/* Make the message and button responsive */
@media (max-width: 768px) {
  .browse-message {
      font-size: 1rem; /* Adjust font size for smaller screens */
      padding: 0 15px; /* Add some padding for better spacing */
  }

  .browse-problems-button {
      padding: 8px 16px; /* Adjust button size for smaller screens */
      font-size: 0.9rem; /* Slightly smaller font */
  }
}
