/* AccountPage.css */

.account-page-container {
  font-family: "Inter", sans-serif; /* Use a consistent font */
  display: flex;
  flex-direction: column; /* Align items vertically */
  align-items: center; /* Center content horizontally */
  min-height: 100vh; /* Ensure content fills the page */
  background-color: #f4f4f4; /* Light background color */
  padding: 20px;
  color: #333; /* Dark text color for contrast */
}

.account-header {
  text-align: center; /* Center the header text */
  margin-bottom: 20px;
  color: #282c34; /* Example - consistent with other headings */
}

.account-details {
  display: grid; /* Use grid for dashboard layout */
  grid-template-columns: repeat(
    auto-fit,
    minmax(300px, 1fr)
  ); /* Responsive columns */
  gap: 20px;
  width: 80%; /* Occupy a portion of the page width */
  max-width: 1200px; /* Limit max width for large screens */
}

.account-details-top {
  display: grid;
  grid-template-columns: 1fr 1fr; /* Two equal-width columns */
  gap: 20px; /* Spacing between sections */
  width: 100%; /* Full width */
  max-width: 1200px; /* Max width for large screens */
  margin-bottom: 20px; /* Space below the top sections */
}

.account-section-containers {
  background-color: white;
  border-radius: 8px;
  padding: 20px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1); /* Add a subtle shadow */
}

.account-section-headers {
  color: #282c34; /* Example color */
  margin-bottom: 15px;
  border-bottom: 1px solid #eee; /* A subtle line under the header */
  padding-bottom: 10px;
}

.profile-section,
.achievements-section {
  background-color: white;
  border-radius: 8px;
  padding: 20px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1); /* Subtle shadow */
}

.progress-section {
  grid-column: span 2; /* Span the full width */
  background-color: white;
  border-radius: 8px;
  padding: 20px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1); /* Subtle shadow */
  margin-bottom: 60px;
}

/* Enhanced Table Styling */
.progress-table {
  width: 100%; /* Full width for the table */
  overflow-x: auto; /* Enable horizontal scrolling if needed */
}

.progress-table th,
.progress-table td {
  padding: 12px 15px; /* More spacious padding for readability */
  text-align: left; /* Align text to the left */
  border-bottom: 1px solid #ddd; /* Subtle row dividers */
}

.progress-table th {
  background-color: #282c34; /* Dark header background */
  color: white; /* White text for contrast */
  font-weight: 600;
  text-transform: uppercase; /* Make text uppercase for a modern look */
  letter-spacing: 0.05em; /* Add slight spacing for readability */
}

.progress-table tbody tr:nth-child(odd) {
  background-color: #f9f9f9; /* Light gray background for odd rows */
}

.progress-table tbody tr:nth-child(even) {
  background-color: #ffffff; /* White background for even rows */
}

.progress-table tbody tr:hover {
  background-color: #f1f5f8; /* Light blue-gray on hover for interactivity */
  transition: background-color 0.3s ease-in-out; /* Smooth hover effect */
}

.progress-table td {
  color: #333; /* Dark gray for text readability */
}

.progress-table td:last-child {
  font-weight: bold; /* Highlight the status column */
  color: #4caf50; /* Green for "Completed" */
}

.progress-table td:last-child.in-progress {
  color: #ff9800; /* Orange for "In Progress" */
}

.progress-empty {
  text-align: center; /* Center the message */
  color: #555; /* Slightly darker text color for visibility */
  margin-top: 20px;
  font-style: italic; /* Italicize for emphasis */
}

/* Media Query for Responsiveness */
@media (max-width: 768px) {
  .progress-table {
    font-size: 14px; /* Reduce font size for smaller screens */
  }

  .progress-table th,
  .progress-table td {
    padding: 10px; /* Adjust padding for smaller screens */
  }
}


/* Enhanced Achievements Styling */

.achievements-progress {
  display: flex;
  flex-direction: column;
  gap: 15px; /* Spacing between progress bars */
}

.achievement-progress-bar {
  background: #f0f0f5; /* Soft background for the bar container */
  border-radius: 10px; /* Rounded corners */
  padding: 10px;
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1); /* Subtle shadow */
  position: relative; /* For positioning the progress text */
  overflow: hidden; /* Hide excess progress fill */
}

.achievement-label {
  font-weight: 600;
  margin-bottom: 5px;
  color: #333; /* Darker label text */
}

progress {
  -webkit-appearance: none; /* Remove default styles */
  appearance: none;
  width: 100%;
  height: 20px; /* Thicker progress bar */
  border-radius: 5px;
  background-color: #e0e0e5; /* Light gray base color */
  overflow: hidden; /* Ensure rounded corners */
}

progress::-webkit-progress-value {
  /* Styling for WebKit browsers */
  background-color: #4caf50; /* Vibrant green progress color */
  border-radius: 5px;
  transition: width 0.5s ease-in-out; /* Smooth progress animation */
}

progress::-webkit-progress-bar {
  background-color: #e0e0e5; /* Maintain light gray bar background */
  border-radius: 5px;
}

progress::-moz-progress-bar {
  /* Firefox styling */
  background-color: #4caf50; /* Green progress */
  border-radius: 5px;
  transition: width 0.5s ease-in-out;
}

.achievement-progress-text {
  position: absolute; /* Position text over the progress bar */
  top: 56%;
  left: 50%;
  transform: translate(-50%, -50%);
  font-size: 14px;
  color: white; /* White text for better visibility */
  font-weight: bold;
  z-index: 1; /* Ensure text is on top */
}

.achievements-earned {
  /* Style for the earned achievements section */
  margin-top: 20px;
}

/* Media Query for Responsiveness (Example) */
@media (max-width: 768px) {
  .account-details {
    grid-template-columns: 1fr; /* Single column on smaller screens */
  }
}

.password-reset-section {
  background-color: #fff;
  padding: 20px;
  border-radius: 8px;
  box-shadow: 0 4px 15px rgba(0, 0, 0, 0.2);
  width: 300px;
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 10px;
  margin-top: 20px;
  text-align: center; /* Center text horizontally */
}

.password-reset-form-group {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 10px;
  width: 100%; /* Ensure the form group takes full width */
}

.password-reset-form-group label {
  display: block;
  font-weight: bold;
  margin-bottom: 5px;
}

.password-reset-form-group input {
  width: 80%; /* Ensure the input takes full width */
  padding: 10px;
  border: 1px solid #ccc;
  border-radius: 4px;
}

.password-reset-button {
  background-color: #1abc9c;
  margin-top: 10px;
  color: white;
  padding: 10px;
  font-size: 16px;
  font-weight: bold;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  transition: background-color 0.3s ease;
}

.password-reset-button:hover {
  background-color: #16a085;
}

.password-change-message {
  margin-top: 10px;
  color: #333;
}