/* NavBar.css */
.navbarv2 {
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: 20px;
  padding: 14px 28px;
  background-color: #2c2c2c; /* Slightly softer black */
  color: #ffffff;
  font-family: "Fira Code", monospace;
  box-shadow: 0 4px 12px rgba(0, 0, 0, 0.25);
  border-bottom: 1px solid #444;
  position: sticky;
  top: 0;
  z-index: 100;
}

/* Brand Logo */
.navbar-brand {
  display: flex;
  align-items: center;
  gap: 24px;
}

.navbar-brand .brand {
  font-size: 1.6rem;
  font-weight: bold;
  color: #1abc9c;
  text-decoration: none;
  transition: color 0.2s ease;
}

.navbar-brand .brand:hover {
  color: #16a085;
}

.menu-item {
  font-size: 1rem;
  color: #e0e0e0;
  text-decoration: none;
  margin-left: 20px;
  font-weight: 500;
  transition: color 0.2s ease;
}

.menu-item:hover {
  color: #1abc9c;
}

/* Right-side actions */
.navbar-actions {
  display: flex;
  align-items: center;
  gap: 20px;
}

.navbar-user {
  font-size: 1rem;
  font-weight: 500;
  color: #ffffff;
}

.navbar-user:hover {
  text-decoration: underline;
}

/* Login / Logout Button */
.login-button,
.logout-button {
  background-color: #1abc9c;
  color: white;
  padding: 8px 18px;
  border-radius: 6px;
  border: none;
  font-size: 0.95rem;
  font-family: "Fira Code", monospace;
  font-weight: 600;
  cursor: pointer;
  transition: all 0.3s ease;
  box-shadow: 0 2px 6px rgba(0, 0, 0, 0.15);
}

.login-button:hover,
.logout-button:hover {
  background-color: #16a085;
  transform: translateY(-2px);
  box-shadow: 0 4px 12px rgba(0, 0, 0, 0.2);
}

/* Forgot Password */
.forgot-password-button {
  background: none;
  border: none;
  color: #1abc9c;
  cursor: pointer;
  text-decoration: underline;
  font-size: 14px;
  font-family: "Fira Code", monospace;
  font-weight: normal;
  margin-top: 10px;
  transition: color 0.3s ease;
}

.forgot-password-button:hover {
  color: #16a085;
}

/* Pulse Animation (used elsewhere) */
@keyframes pulse {
  0% {
    transform: scale(1);
    box-shadow: 0 0 0 rgba(26, 188, 156, 0.7);
  }
  50% {
    transform: scale(1.1);
    box-shadow: 0 0 10px rgba(26, 188, 156, 0.7);
  }
  100% {
    transform: scale(1);
    box-shadow: 0 0 0 rgba(26, 188, 156, 0.7);
  }
}

.pulse-animation {
  animation: pulse 1s ease-in-out infinite;
}