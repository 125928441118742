/* Test Case Container */
.test-case-container {
    box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
    overflow: auto;
    height: 94vh;
    font-family: "Fira Code", monospace;
}

.test-heading {
    font-family: "Fira Code", monospace;
    margin: 0;
    margin-bottom: 15px;
    padding: 5px 5px 5px 10px; /* Adds slight top & bottom padding */
    font-size: 18px; /* Smaller font for a clean look */
    font-weight: bold;
    color: white; /* White text for contrast */
    text-align: left;
    background-color: rgba(72, 67, 67);
    border: none; /* Removes the dotted border */
    position: sticky;
    top: 0;
    z-index: 1;
}

/* Test Tabs (Navigation for Test Cases) */
.test-tabs {
    display: flex;
    padding: 8px;
    border-bottom: 2px solid #e0e0e0;
}

.test-tab {
    flex: 1;
    padding: 10px;
    font-weight: 600;
    font-size: 14px;
    text-align: center;
    /* color: #ffffff; */
    background: transparent;
    border: none;
    cursor: pointer;
    transition: 0.3s;
}

.test-tab.active {
    background: #444;
    font-weight: bold;
    border-bottom: 3px solid #f89c0e; /* LeetCode's Orange */
}

/* Hover Effect */
.test-tab:hover {
    background: rgba(255, 255, 255, 0.1);
}

/* Test Case Display */
.test-case-card {
    padding: 15px;
    border-radius: 0 0 8px 8px;
}

/* Test Content */
.test-case-content {
    font-size: 14px;
    padding: 15px;
    color: white;
    border-radius: 6px;
    box-shadow: 0 2px 6px rgba(0, 0, 0, 0.08);
}

.test-input,
.test-output {
    margin-bottom: 8px;
}

.test-input strong,
.test-output strong {
    color: white;
    font-size: 13px;
}

/* Code Block Styling */
pre {
    background: #444;
    padding: 10px;
    border-radius: 6px;
    font-family: "Fira Code", monospace;
    font-size: 13px;
    overflow-x: auto;
}

/* Passed & Failed Status */
.test-status {
    font-weight: bold;
    padding: 8px;
    border-radius: 6px;
    display: inline-block;
    font-size: 14px;
    text-align: center;
}

.test-status.passed {
    color: #155724;
    background: #d4edda;
    border-left: 4px solid #28a745;
}

.test-status.failed {
    color: #721c24;
    background: #f8d7da;
    border-left: 4px solid #dc3545;
}

/* Summary Box */
.test-summary {
    margin-top: 15px;
    padding: 10px;
    font-weight: bold;
    text-align: center;
    font-size: 14px;
    border-radius: 5px;
}

.test-summary.success {
    background: #28a745;
    color: white;
    border-left: 4px solid #1e7e34;
}

.test-summary.error {
    background: #dc3545;
    color: white;
    border-left: 4px solid #c82333;
}

.spinner-wrapper {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 120px;
  }  
