.solution-blocks-holder {
    min-height: 200px;
    max-height: 100%; /* Let it grow naturally */
    display: flex;
    flex-direction: column;
    /* padding: 10px; */
    overflow: hidden; /* Contain overflow cleanly */
  }

.solution-title-group {
    display: flex;
    align-items: center;
    gap: 12px;
  }

.solution-header {
    margin: 0;
    margin-bottom: 0px;
    padding: 5px 10px;
    font-size: 18px;
    font-weight: bold;
    color: white;
    background-color: rgba(72, 67, 67);
    border: none;
    position: sticky;
    top: 0;
    z-index: 1;
  }
  
  /* New layout for header row */
  .header-row {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
  
  /* Solution name */
  .solution-title {
    font-size: 18px;
    font-weight: bold;
    color: white;
  }
  
  /* ✅ Solved badge */
  .solved-inline {
    color: #2ecc71;
    font-weight: bold;
    font-size: 1rem;
  }
  

.render-block-content {
    display: flex;
    justify-content: space-evenly;
    align-items: center;
}

/* .block-stuff {
    display: flex;
    flex-direction: column;
    align-items: center;
    height: 100%;
} */
