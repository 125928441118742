/* General container styling */
.add-prob {
    display: flex;
    flex-direction: column;
}
  
  /* Title styling */
  .copy-paste-header {
    font-size: 28px;
    font-weight: bold;
    margin-bottom: 20px;
    color: #3498db;
    text-align: center;
  }
  
  /* Textarea styling */
  .copy-paste-area {
    width: 90vw;
    height: 50vh;
    padding: 15px;
    border-radius: 8px;
    border: 1px solid #d3d3d3;
    background-color: #f4f6fa;
    font-size: 16px;
    line-height: 1.5;
    resize: none;
    transition: border-color 0.3s ease;
    box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1);
    margin-bottom: 20px;
  }
  
  textarea:focus {
    outline: none;
    border-color: #4a90e2;
    box-shadow: 0 0 10px rgba(74, 144, 226, 0.3);
  }
  
  /* Button styling */
  button {
    padding: 10px 20px;
    margin: auto;
    font-size: 16px;
    color: #fff;
    background-color: #3498db;
    border: none;
    border-radius: 6px;
    cursor: pointer;
    transition: background-color 0.3s ease, transform 0.3s ease;
    box-shadow: 0 4px 10px rgba(74, 144, 226, 0.3);
  }
  
  button:hover {
    background-color: #357ab8;
    transform: translateY(-2px);
  }
  
  button:active {
    background-color: #2c6a9e;
    transform: translateY(0);
  }
  
  /* Loading spinner and text */
  .loading-container {
    display: flex;
    align-items: center;
    flex-direction: column;
    margin-top: 20px;
    color: #4a90e2;
    font-weight: bold;
    animation: fadeIn 0.5s ease;
  }
  
  @keyframes fadeIn {
    from {
      opacity: 0;
      transform: translateY(10px);
    }
    to {
      opacity: 1;
      transform: translateY(0);
    }
  }
  
  /* Error message styling */
  .error {
    color: #d9534f;
    font-weight: bold;
    margin-top: 10px;
    background-color: #fbe3e4;
    padding: 10px;
    border-radius: 5px;
    text-align: center;
    max-width: 100%;
  }
  