/* BeginnerBlocks.css */

/* Container for the block library */
.new-block-library-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    background: #252526; /* Matches left-pane background */
    border-radius: 8px;
    padding: 16px;
    width: 90%;
    color: #d4d4d4;
    font-family: "Fira Code", monospace;
}

/* Header for the block library */
.new-block-library-header {
    font-size: 16px;
    font-weight: bold;
    color: #ffaa00; /* LeetCode yellow */
    margin-bottom: 10px;
    text-align: center;
}

/* Container for block buttons */
.new-block-library {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    gap: 8px;
    width: 100%;
    padding: 8px;
}

/* Block button styles */
.new-block-button {
    background: linear-gradient(145deg, #3a3a3a, #2a2a2a);
    color: #ffffff;
    font-size: 14px;
    font-weight: bold;
    border: 1px solid #3c3c3c;
    border-radius: 6px;
    padding: 8px 12px;
    min-width: 100px;
    cursor: pointer;
    transition: all 0.2s ease-in-out;
    text-align: center;
    box-shadow: 2px 2px 5px rgba(0, 0, 0, 0.2);
}

/* Hover effect */
.new-block-button:hover {
    background: #ffaa00; /* LeetCode yellow */
    color: black;
    transform: scale(1.05);
    border: 1px solid #ffaa00;
}

/* Active click effect */
.new-block-button:active {
    transform: scale(0.95);
    box-shadow: none;
}

/* Different block types */
.new-block-button.loop {
    background: linear-gradient(145deg, #007bff, #0056b3); /* Blue */
}

.new-block-button.condition {
    background: linear-gradient(145deg, #28a745, #1e7e34); /* Green */
}

.new-block-button.function {
    background: linear-gradient(145deg, #e83e8c, #c2185b); /* Pink */
}

.new-block-button.variable {
    background: linear-gradient(145deg, #f8c102, #d39e00); /* Yellow */
}

/* Hover effects for block types */
.new-block-button:hover.loop {
    background: #0056b3;
}

.new-block-button:hover.condition {
    background: #1e7e34;
}

.new-block-button:hover.function {
    background: #c2185b;
}

.new-block-button:hover.variable {
    background: #d39e00;
}

/* Mobile responsiveness */
@media (max-width: 768px) {
    .new-block-library {
        flex-direction: column;
        align-items: center;
    }

    .new-block-button {
        width: 80%;
        min-width: unset;
    }
}
