.survey-container {
    padding: 20px;
    max-width: 600px;
    margin: 0 auto;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    position: relative;
    min-height: 100vh; /* Full viewport height */
  }
  
  .survey-title {
    text-align: center;
    font-size: 24px;
    margin-bottom: 20px;
  }
  
  .survey-form {
    display: flex;
    flex-direction: column;
    gap: 20px;
    flex-grow: 1;
  }
  
  .form-group {
    display: flex;
    flex-direction: column;
  }
  
  label {
    font-weight: bold;
    margin-bottom: 8px;
  }
  
  input[type="text"],
  textarea,
  select {
    padding: 8px;
    font-size: 14px;
    border: 1px solid #ccc;
    border-radius: 5px;
    width: 100%;
  }
  
  textarea {
    resize: none;
    height: 80px;
  }
  
  input[type="range"] {
    width: 100%;
    margin-top: 8px;
  }
  
  p {
    font-size: 12px;
    color: #555;
    margin-top: 5px;
  }
  
  .submit-button {
    background-color: #1abc9c;
    color: white;
    font-size: 16px;
    font-weight: bold;
    padding: 12px 20px;
    border: none;
    border-radius: 5px;
    cursor: pointer;
    transition: background-color 0.3s ease, transform 0.2s ease;
    margin-top: 20px;
    align-self: center; /* Center the button */
  }
  
  .submit-button:hover {
    background-color: #16a085;
    transform: scale(1.05);
  }
  
  .submit-button:active {
    transform: scale(1);
  }
  
  /* Add spacing at the bottom to avoid overlapping with the navbar */
  .survey-form {
    padding-bottom: 80px; /* Add space above the navbar */
  }
  