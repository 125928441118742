.entry-container {
  display: flex;
  flex-direction: column;
  justify-content: center; /* center vertically */
  align-items: center;
  height: 100vh;
  background-color: #f9f9f9;
  color: #2d3436;
  font-family: "Inter", sans-serif;
  text-align: center;
  overflow: hidden;
  padding: 0 20px;
  position: relative;
}


.entry-content {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-bottom: 40px; /* leaves room for buttons to come in */
  transition: transform 0.3s ease;
}

.entry-tagline {
  font-size: 2rem;
  font-weight: bold;
  color: #3498db;
  margin-top: 20px;
  text-align: center;
}

.logo-wrapper {
  display: flex;
  align-items: center;
  gap: 16px;
}

.logo-squares {
  display: grid;
  grid-template-columns: repeat(2, 10px);
  grid-template-rows: repeat(2, 10px);
  gap: 5px;
}

.square {
  width: 10px;
  height: 10px;
  border-radius: 2px;
  background-color: #1abc9c;
  transition: transform 0.2s ease;
}

.square1 {
  background-color: #edf2f1;
  border: 1px rgba(0, 0, 0, 0.592) solid;
}
.square2 {
  background-color: #000302;
  border: 1px #1abc9c solid;
}
.square3 {
  background-color: #edf2f1;
  border: 1px rgba(0, 0, 0, 0.592) solid;
}
.square4 {
  background-color: #1abc9c;
  border: 1px black solid;
}

.blockcode-logo {
  display: flex;
  align-items: center;
  font-family: "Inter", sans-serif;
  font-weight: 700;
  font-size: 2.6rem;
  letter-spacing: -0.5px;
}

.logo-block {
  color: #2d3436;
  margin-right: 0px;
}

.logo-divider {
  color: #dcdcdc;
  margin: 0 5px;
  font-weight: 400;
}

.logo-code {
  font-family: "Source Code Pro", monospace;
  color: #1abc9c;
  font-weight: 600;
}

.button-container {
  display: flex;
  /* flex-direction: column; */
  align-items: center;
  gap: 20px;
  padding-top: 0; /* Prevents vertical space explosion */
  opacity: 0;
}

.entry-tagline {
  font-size: 1.85rem;
  font-weight: 500;
  color: #2d3436;
  margin-top: 20px;
  text-align: center;
  line-height: 1.4;
}

/* CODE */
.code-style {
  font-family: "Source Code Pro", monospace;
  background-color: #1e1e1e;
  color: white; /* soft orange */
  padding: 3px 8px;
  border-radius: 6px;
  font-size: 1.05em;
}

/* BLOCK */
.block-style {
  background-color: #3498db; /* soft brand blue */
  color: white;
  font-weight: 700;
  padding: 4px 14px;
  border-radius: 12px;
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.15);
  font-family: "Inter", sans-serif;
  display: inline-block;
  transform: translateY(-1px);
  font-size: 1.05em;
}



.entry-button {
  background-color: #1abc9c;
  color: white;
  font-size: 1.2rem;
  font-weight: bold;
  padding: 15px 30px;
  border: none;
  border-radius: 10px; /* Rounded square */
  cursor: pointer;
  transition: transform 0.2s ease-in-out, background-color 0.2s;
}

.entry-button:hover {
  transform: scale(1.1);
  background-color: #16a085; /* Slightly darker turquoise */
}

  
  .scroll-in {
    opacity: 0;
    transform: translateX(50vw);
    animation: scrollIn 1.5s ease-out forwards;
    font-size: 3rem; /* Match homepage size */
    font-weight: bold;
    color: #1abc9c; /* Apply Blockcode brand color */
  }
  
  @keyframes scrollIn {
    from {
      opacity: 0;
      transform: translateX(50vw);
    }
    to {
      opacity: 1;
      transform: translateX(0);
    }
  }
  
  .entry-content p.fizzle-in {
    opacity: 0;
    font-size: 2.5rem; /* Bigger size */
    font-weight: bold;
    color: #1abc9c; /* Force turquoise color */
    animation: fizzleIn 1.5s ease-in forwards;
    animation-delay: 1.5s;
  }
  
  
  
  
  @keyframes fizzleIn {
    0% {
      opacity: 0;
    }
    50% {
      opacity: 0.3;
    }
    80% {
      opacity: 0.6;
    }
    100% {
      opacity: 1;
    }
  }
  
  
