.blocks-sticky-header {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin: 0;
  margin-bottom: 15px;
  padding: 5px 10px;
  font-size: 14px;
  font-weight: bold;
  color: white;
  background-color: rgba(72, 67, 67);
  border: none;
  position: sticky;
  top: 0;
  z-index: 1;
}

/* Flex zones for proper alignment */
.left-block,
.center-block,
.right-block {
  flex: 1;
  display: flex;
  align-items: center;
}

.left-block {
  justify-content: flex-start;
}

.center-block {
  justify-content: center;
}

.right-block {
  justify-content: flex-end;
}


.hint-button {
    margin-left: auto; /* Pushes it to the far right */
    background-color: #4caf50;
    color: white;
    padding: 6px 12px;
    font-size: 14px;
    border: none;
    border-radius: 6px;
    font-weight: bold;
    cursor: pointer;
    transition: background-color 0.2s ease-in-out;
    height: fit-content;
    align-self: center;
  }
  
  .hint-button:hover:not(.disabled) {
    background-color: #45a049;
  }
  
  .hint-button.disabled {
    background-color: gray;
    cursor: not-allowed;
  }
  

.block-library-header {
  font-size: 18px;
  margin: 0px;
}

.blocks-footer {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    padding: 15px;
    background-color: rgba(50, 50, 50, 0.9);
    color: white;
    font-size: 14px;
    font-weight: bold;
    text-align: center;
    box-shadow: 0 -2px 10px rgba(0, 0, 0, 0.2);
    border-top: 2px solid #ffaa00;
    width: 100%;
  }

  .big-o-button {
    background-color: #2ecc71;
    color: white;
    border: none;
    padding: 6px 12px;
    border-radius: 6px;
    font-weight: bold;
    font-size: 0.85rem;
    cursor: pointer;
    font-family: var(--font-family);
    box-shadow: 0 0 6px rgba(0, 0, 0, 0.3);
    transition: transform 0.2s ease;
  }
  
  /* Pulse animation */
  /* .pulse {
    animation: pulse 1.2s infinite;
  }
  
  @keyframes pulse {
    0% {
      transform: scale(1);
      box-shadow: 0 0 0px rgba(46, 204, 113, 0.6);
    }
    50% {
      transform: scale(1.05);
      box-shadow: 0 0 10px rgba(46, 204, 113, 0.9);
    }
    100% {
      transform: scale(1);
      box-shadow: 0 0 0px rgba(46, 204, 113, 0.6);
    }
  } */
  


  